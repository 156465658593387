import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import { useUser } from "../../../helpers/userContext";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";
import parse from "html-react-parser";

export default function ProjectType() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.projectType;
	const { lang } = useLang();

	const [index, setIndex] = useState("");
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");
	const [responsibleStep3, setResponsibleStep3] = useState([]);
	const [usersStep3, setUsersStep3] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [abbreviation, setAbbreviation] = useState("");

	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const modelSendToServer = {
		index,
		abbreviation,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		},
		steps: [
			{
				index: 3,
				responsible: responsibleStep3,
				users: usersStep3
			}
		].filter((step) => step.responsible.length > 0 || (step.users && step.users.length > 0))
	};

	const columns = [
		{
			accessorFn: (row) => `${row.firstName?.en} ${row.firstName?.ru} ${row.firstName?.hy}`,
			header: T("firstName", lang),
			filterVariant: "multi-select",
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.firstName?.[lang] ? row.firstName?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row.lastName?.en} ${row.lastName?.ru} ${row.lastName?.hy}`,
			header: T("lastName", lang),
			filterVariant: "multi-select",
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.lastName?.[lang] ? row.lastName?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		}
	];

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawUsers = await API.get({
				route: config.api.user,
				query: {
					temporary: {
						$ne: true
					},
					workingStatus: "working"
				}
			});
			setAllUsers(rawUsers);
			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					populate: ["steps.responsible", "steps.users"]
				});
				setIndex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);
				setAbbreviation(currentItem[0]?.abbreviation);

				if (currentItem[0]?.steps) {
					currentItem[0]?.steps.forEach((step) => {
						if (step.index === 3) {
							setResponsibleStep3(step.responsible);
							setUsersStep3(step.users);
						}
					});
				}
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel="projectType"
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						lang: "en",
						permissionModel: "name"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInRussian", lang),
						lang: "ru",
						permissionModel: "name"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInArmenian", lang),
						lang: "hy",
						permissionModel: "name"
					},
					{
						value: abbreviation,
						setter: setAbbreviation,
						type: "text",
						usageType: "textfield",
						label: T("abbreviation", lang),
						placeholder: T("abbreviation", lang),
						permissionModel: "abbreviation"
					}
				]}
				cartComponent={{
					title: T("responsibleAndUsers", lang),
					data: [
						{
							placeholder: T("responsible", lang) + " - " + config.steps[2].name[lang] + " step3",
							value: responsibleStep3,
							setter: setResponsibleStep3,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder: T("users", lang) + " - " + config.steps[2].name[lang] + " step3",
							value: usersStep3,
							setter: setUsersStep3,
							data: allUsers,
							columns: columns,
							permissionModel: "users",
							width: "220px"
						}
					]
				}}
			/>
		);
}
