import React, { useState, useEffect, useMemo } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress from "nprogress";
import { T } from "../../helpers/translator";

export default function ChatChannel() {
	const [isBusy, setBusy] = useState(true);
	const route = config.api.chat;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);
	let id = useParams().id;

	const [index, setIndex] = useState("");

	const [allUsers, setAllUsers] = useState([]);

	const [parentChatId, setParentChatId] = useState(null);
	const [privateChat, setPrivate] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");

	const [users, setUsers] = useState([]);

	const modelSendToServer = {
		index,
		users,
		parentChatId,
		privateChat,
		readOnly,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		}
	};

	const columns = [
		{
			accessorFn: (row) => `${row.name.en} ${row.name.ru} ${row.name.hy}`,
			header: T("name"),
			filterVariant: "multi-select",
			Cell: (
				ReceivedObject,
				cell = ReceivedObject.renderedCellValue,
				row = ReceivedObject.row.original
			) => {
				let result = parse(row.name[lang] ? row.name[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		}
	];

	//mongoose model
	// users: [
	// 	{
	// 		type: Schema.Types.ObjectId,
	// 		ref: 'user_model',
	// 		admin: Boolean,
	// 	},
	// ],
	// parentChatId: { type: Schema.Types.ObjectId, ref: 'chat_group_model' },
	// privateChat: Boolean,
	// readOnly: Boolean,
	// name: {
	// 	en: String,
	// 	ru: String,
	// 	hy: String,
	// },
	// // optimizing for search
	// messagesCount: Number,
	// lastMessage: { type: Schema.Types.ObjectId, ref: 'message_model' },
	// usernames: [String],

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setAllUsers(rawUsers);
			if (id !== "add") {
				let currentItem = await API.get(
					route + "/channel",
					{
						_id: id
					},
					null,
					null,
					null,
					["users"]
				);
				console.log("🚀 ~ currentItem:", currentItem);
				setIndex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);
				setUsers(currentItem[0]?.users);
			}

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route + "/channel"}
				id={id}
				permissions={permissions}
				permissionModel="branch"
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index"),
						placeholder: T("index"),
						permissionModel: "index"
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: T("name"),
						placeholder: T("inputInLatin"),
						lang: "en",
						permissionModel: "name"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: T("name"),
						placeholder: T("inputInRussian"),
						lang: "ru",
						permissionModel: "name"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: T("name"),
						placeholder: T("inputInArmenian"),
						lang: "hy",
						permissionModel: "name"
					}
				]}
				cartComponent={{
					title: T("branches", lang),
					data: [
						{
							placeholder: T("branch"),
							value: users,
							setter: setUsers,
							data: allUsers,
							columns: columns,
							width: "220px",
							quantity: "off",
							permissionModel: "users"
						}
					]
				}}
			/>
		);
}
