import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { T } from "../../helpers/translator";

import { useUser } from "../../helpers/userContext";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import Stepper from "./modules/stepper";
import Swal from "sweetalert2";
import { useColorScheme, useDisclosure } from "@mantine/hooks";
import NProgress from "nprogress";
import { useMediaQuery } from "@mantine/hooks";

import {
	Card,
	Text,
	SimpleGrid,
	UnstyledButton,
	Anchor,
	Group,
	useMantineTheme,
	useMantineColorScheme,
	Spoiler,
	Box,
	Button,
	Collapse,
	Flex,
	Title,
	Grid,
	Modal,
	ScrollArea,
	CloseIcon,
	FileInput,
	rem,
	CardSection
} from "@mantine/core";
import classes from "./style.module.css";
import {
	IconCreditCard,
	IconBuildingBank,
	IconRepeat,
	IconReceiptRefund,
	IconReceipt,
	IconReceiptTax,
	IconReport,
	IconCashBanknote,
	IconCoin,
	IconId,
	IconCalendar,
	IconCalendarCheck,
	IconCalendarMonth,
	IconCircuitVoltmeter,
	IconShare2,
	IconPictureInPictureOn,
	IconDeviceFloppy,
	IconArrowBack
} from "@tabler/icons-react";
import Step1 from "./modules/step1";
import Step2 from "./modules/step2";
import Step3 from "./modules/step3";
import Step4 from "./modules/step4";
import Step5 from "./modules/step5";
import Step6 from "./modules/step6";
import Step7 from "./modules/step7";
import Step8 from "./modules/step8";
import Step9 from "./modules/step9";
import Step10 from "./modules/step10";
import Step11 from "./modules/step11";
import Step12 from "./modules/step12";
import Step13 from "./modules/step13";
import Step14 from "./modules/step14";
import Step15 from "./modules/step15";
import Step16 from "./modules/step16";
import Step17 from "./modules/step17";
import Step18 from "./modules/step18";
import Step19 from "./modules/step19";
import Step20 from "./modules/step20";
import Step21 from "./modules/step21";
import Step22 from "./modules/step22";
import Step23 from "./modules/step23";
import Step24 from "./modules/step24";
import Step25 from "./modules/step25";
import Step26 from "./modules/step26";
import Step27 from "./modules/step27";
import Step28 from "./modules/step28";
import Step29 from "./modules/step29";
import Step30 from "./modules/step30";
import Step31 from "./modules/step31";
import Step32 from "./modules/step32";
import Step33 from "./modules/step33";
import Step34 from "./modules/step34";
import Step35 from "./modules/step35";
import Step36 from "./modules/step36";
import Step37 from "./modules/step37";
import Step38 from "./modules/step38";
import Step39 from "./modules/step39";
import Step40 from "./modules/step40";
import Step41 from "./modules/step41";
import Step42 from "./modules/step42";
import Step43 from "./modules/step43";
import Step44 from "./modules/step44";
import Step45 from "./modules/step45";
import ReactImageGallery from "react-image-gallery";

export default function ProjectPage() {
	const [isBusy, setBusy] = useState(true);
	let id = useLocation().pathname.split("/")[3];
	const route = config.api.project;
	const { user } = useUser();
	const { lang } = useLang();
	const [opened, Toggle] = useDisclosure(false);
	const [opened2, Toggle2] = useDisclosure(true);
	const [openedInner, ToggleInner] = useDisclosure(true);
	//! global variasbles
	const [ID, setID] = useState("");
	const [priority, setPriority] = useState(0); //  0 less , 1 mid , 2 high
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState("");
	const [projectType, setProjectType] = useState(null);
	const [projectTypes, setProjectTypes] = useState("");
	const [community, setCommunity] = useState(null);
	const [city, setCity] = useState(null);
	const [village, setVillage] = useState(null);
	const [branch, setBranch] = useState(null);
	// const [substation, setSubstation] = useState("");
	const [voltage, setVoltage] = useState(null);
	const [orderNumber, setOrderNumber] = useState("");
	const [oldProject, setOldProject] = useState(false);
	const [address, setAddress] = useState({
		en: "",
		ru: "",
		hy: ""
	});
	const [workingDays, setWorkingDays] = useState("");
	const [step, setStep] = useState(1); // current selected step
	const [activeStep, setActiveStep] = useState(undefined); // current selected step
	// all possible steps
	const [steps, setSteps] = useState([]);
	// local valid steps
	const [localSteps, setLocalSteps] = useState(config.steps);
	const [history, setHistory] = useState([{}]);
	const [users, setUsers] = useState([{}]);
	let modelSendToServer = {};
	const theme = useMantineTheme();
	const { colorScheme } = useMantineColorScheme();
	const [permissions, setPermissions] = useState([]);
	const [isMobile, setIsMobile] = useState("");
	const [projectPartition, setProjectPartition] = useState("");
	const [price, setPrice] = useState("");
	const [disabledSteps, setDisabledSteps] = useState([]);

	// modal and pdf show variables
	const [modalStartIndex, setModalStartIndex] = useState(0);
	const [opened3, { open, close }] = useDisclosure(false);
	const [files, setFiles] = useState([]);
	const [fullScreen, setFullScreen] = useState(false);
	const [base64, setBase64] = useState(null);

	async function removeFile({ id, index }) {
		if (id) {
			Swal.fire({
				title: T("areYouSure", lang),
				text: T("youCannotRevertThisAction", lang),
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: T("yes", lang)
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (files) {
						try {
							await API.remove(config.api.upload, id);
							setBusy(true);
							setFiles(null);
						} catch (err) {
							console.error(err);
							Swal.fire({
								title: "Error!",
								text: config.translate.swal.error[lang],
								icon: "error",
								confirmButtonText: "Ok"
							});
						}
					}
					setBase64(null);
				}
			});
		} else if (index || index === 0) {
			Swal.fire({
				title: T("areYouSure", lang),
				text: T("youCannotRevertThisAction", lang),
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: T("yes", lang)
			}).then(async (result) => {
				if (base64) {
					let tempBase64 = [...base64];
					tempBase64.splice(index, 1);
					setBase64(tempBase64);
					if (files && files.length > 1) {
						setFiles && setFiles(tempBase64);
					}
				}
			});
		}
	}

	async function handleSave(files, lang, destiny, alt) {
		let Files = Array.from(files);
		if (Files.length > 0) {
			if (setFiles) {
				setFiles && setFiles(Files);
			}
		} else {
			Swal.fire({
				title: "Error!",
				text: "Error with file selection!",
				icon: "error",
				confirmButtonText: "Ok"
			});
		}
	}

	const saveItem = async () => {
		Swal.fire({
			title: T("areYouSure", lang),
			text: T("youCannotRevertThisAction", lang),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: T("yes", lang)
		}).then(async (result) => {
			if (result.isConfirmed) {
				NProgress.start();
				try {
					await API.upload(id, files, lang, "files", () => {}, new Date());
					NProgress.done();
					setBusy(true);
				} catch (error) {
					console.error(error);
				}
			}
		});
	};

	function setEndDateWorkingDays() {
		let localEndDate;
		let localWorkingDays = 0;
		let StartDate = startDate ? new Date(startDate) : new Date();

		if (voltage?.projectDeadline) {
			// localeeddate is a number but we assign it to a date object
			localEndDate = new Date(StartDate.getTime() + voltage.projectDeadline * 24 * 60 * 60 * 1000);
			// Assuming StartDate and localEndDate are defined and are JavaScript Date objects
			// Calculate the difference in working days
		}
		while (StartDate < localEndDate) {
			// Skips Sunday and Saturday
			if (StartDate.getDay() !== 0 && StartDate.getDay() !== 6) {
				localWorkingDays++;
			}
			StartDate.setDate(StartDate.getDate() + 1);
		}
		setWorkingDays(localWorkingDays);
		setEndDate(localEndDate);
	}

	const handleStepChange = (step) => {
		setStep(step._id);
	};

	async function UpdateStep({ step, modelSendToServer, backward = false }) {
		let Next, Previous, Current;
		if (backward) {
			try {
				Previous = await API.update(config.api.step, modelSendToServer.steps[step - 2]._id, {
					status: "inProgress",
					startDate: new Date(),
					endDate: null,
					history: [
						...modelSendToServer.steps[step - 2].history,
						{
							status: "inProgress",
							startDate: new Date(),
							user: user._id
						}
					]
				});
				Current = await API.update(config.api.step, modelSendToServer.steps[step - 1]._id, {
					status: null,
					startDate: null,
					endDate: null,
					desc: {
						en: modelSendToServer.steps[step - 1].desc.en,
						ru: modelSendToServer.steps[step - 1].desc.ru,
						hy: modelSendToServer.steps[step - 1].desc.hy
					},
					history: [
						...modelSendToServer.steps[step - 1].history,
						{
							status: null,
							startDate: null,
							endDate: null,
							user: user._id
						}
					]
				});
				if (Previous && Current) {
					setStep(step - 1);
					return { Previous, Current };
				} else {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong!"
					});
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong!"
				});
			}
		} else {
			try {
				Current = await API.update(config.api.step, modelSendToServer.steps[step - 1]._id, {
					status: "done",
					endDate: new Date(),
					desc: {
						en: modelSendToServer.steps[step - 1].desc.en,
						ru: modelSendToServer.steps[step - 1].desc.ru,
						hy: modelSendToServer.steps[step - 1].desc.hy
					},
					history: [
						...modelSendToServer.steps[step - 1].history,
						{
							status: "done",
							endDate: new Date(),
							user: user._id
						}
					]
				});
				try {
					// test if next step exists
					let [ifStepDoesExists] = await API.get({
						route: config.api.step,
						query: {
							project_id: id,
							queue: step + 1
						}
					});
					// if exists update it, if not create it
					if (ifStepDoesExists) {
						Next = await API.update(config.api.step, ifStepDoesExists._id, {
							status: "inProgress",
							queue: step + 1,
							project_id: id,
							name: {
								en: localSteps[step].name.en,
								ru: localSteps[step].name.ru,
								hy: localSteps[step].name.hy
							},
							desc: {
								en: localSteps[step].desc.en,
								ru: localSteps[step].desc.ru,
								hy: localSteps[step].desc.hy
							},
							startDate: new Date(),
							createdByUser: user._id,
							history: [
								{
									status: "inProgress",
									startDate: new Date(),
									user: user._id
								}
							]
						});
					} else {
						Next = await API.post(config.api.step, {
							status: "inProgress",
							queue: step + 1,
							project_id: id,
							name: {
								en: localSteps[step].name.en,
								ru: localSteps[step].name.ru,
								hy: localSteps[step].name.hy
							},
							desc: {
								en: localSteps[step].desc.en,
								ru: localSteps[step].desc.ru,
								hy: localSteps[step].desc.hy
							},
							startDate: new Date(),
							createdByUser: user._id,
							history: [
								{
									status: "inProgress",
									startDate: new Date(),
									user: user._id
								}
							]
						});
					}
					if (Current && Next) {
						setStep(step + 1);
						return { Current, Next };
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Something went wrong!"
						});
					}
				} catch (error) {
					console.error(error);
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong!"
				});
			}
		}
	}

	modelSendToServer = {
		ID,
		history: history ? history : [],
		priority,
		startDate,
		endDate,
		projectType,
		community,
		city,
		village,
		branch,
		// substation,
		voltage,
		orderNumber,
		address,
		workingDays,
		projectPartition,
		price,
		oldProject,
		disabledSteps,
		steps: steps ? steps.filter((item) => item._id) : []
	};

	const HandlePermissionCheck = (input) => {
		if (user?.ananunaki) {
			input.crud = {
				create: true,
				read: true,
				update: true,
				delete: true
			};
			return input;
		}
		let foundedPermission = user.role.permissions.find(
			(permission) => permission.name === "project"
		);
		if (foundedPermission) {
			let foundedInput = foundedPermission.inputs?.find(
				(inp) => inp.name === input?.permissionModel
			);
			if (foundedInput && foundedInput.read) {
				input.crud = {
					create: foundedInput.create !== undefined ? foundedInput.create : false,
					read: foundedInput.read !== undefined ? foundedInput.read : false,
					update: foundedInput.update !== undefined ? foundedInput.update : false,
					delete: foundedInput.delete !== undefined ? foundedInput.delete : false
				};
				return input;
			}
		} else {
			console.error("Permission not found");
		}
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			try {
				let currentProject = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true,
					populate: [
						"steps",
						"city",
						"village",
						"branch",
						{
							path: "branch",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"voltage",
						{
							path: "voltage",
							populate: [
								{
									path: "steps.responsible"
								},
								{
									path: "steps.users"
								}
							]
						},
						"projectType",
						{
							path: "projectType",
							populate: {
								path: "steps.responsible"
							}
						},
						"community"
					]
				});
				//! set All fields
				setID(currentProject[0]?.ID);
				// set all states from server
				currentProject[0]?.startDate && setStartDate(new Date(currentProject[0]?.startDate));
				currentProject[0]?.endDate && setEndDate(new Date(currentProject[0]?.endDate));
				currentProject[0]?.priority && setPriority(currentProject[0]?.priority);
				currentProject[0]?.address && setAddress(currentProject[0]?.address);
				currentProject[0]?.orderNumber && setOrderNumber(currentProject[0]?.orderNumber);
				currentProject[0]?.workingDays && setWorkingDays(currentProject[0]?.workingDays);
				currentProject[0]?.price && setPrice(currentProject[0]?.price);
				currentProject[0]?.disabledSteps && setDisabledSteps(currentProject[0]?.disabledSteps);
				currentProject[0]?.projectPartition &&
					setProjectPartition(currentProject[0]?.projectPartition);
				currentProject[0]?.community && setCommunity(currentProject[0]?.community);
				currentProject[0]?.branch && setBranch(currentProject[0]?.branch);
				currentProject[0]?.city && setCity(currentProject[0]?.city);
				currentProject[0]?.village && setVillage(currentProject[0]?.village);
				currentProject[0]?.voltage && setVoltage(currentProject[0]?.voltage);
				currentProject[0]?.history && setHistory(currentProject[0]?.history);
				currentProject[0]?.projectType && setProjectType(currentProject[0]?.projectType);
				currentProject[0]?.steps && setSteps(currentProject[0]?.steps);
				currentProject[0]?.oldProject && setOldProject(currentProject[0]?.oldProject);

				// currentProject[0]?.substation && setSubstation(currentProject[0]?.substation);
				setFiles(currentProject[0]?.uploads);

				currentProject[0]?.steps?.forEach((step, index) => {
					if (step.status === "inProgress" && step.bypass !== true) {
						setStep(step.queue);
						setActiveStep(step.queue);
					}
				});

				setBusy(false);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: T("somethingWentWrong", lang)
				});
				history.push("/projects");
				setBusy(false);
			}
		})();
		// eslint-disable-next-line
	}, [isBusy, activeStep]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "project") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
		setIsMobile(window.innerWidth < 768 ? true : false);
	}, []);

	useEffect(() => {
		setEndDateWorkingDays();
		// eslint-disable-next-line
	}, [voltage]);

	// handle page resize
	useEffect(() => {
		// page resize event listerner
		window.addEventListener("resize", () => {
			setIsMobile(window.innerWidth < 768 ? true : false);
		});
	});

	if (isBusy) return <Loading />;
	return (
		<>
			<Flex wrap={"wrap"} align={"center"} gap={20} justify={"center"}>
				<Button
					onClick={() => {
						Toggle.toggle();
					}}
					variant={opened ? "filled" : "outline"}
				>
					{opened ? T("hideProjectDetails", lang) : T("showProjectDetails", lang)}
				</Button>

				<Button
					onClick={() => {
						Toggle2.toggle();
						if (opened2) ToggleInner.close();
						else ToggleInner.open();
					}}
					variant={opened2 ? "filled" : "outline"}
				>
					{opened2 ? T("hideAllSteps", lang) : T("showAllSteps", lang)}
				</Button>
			</Flex>
			<Flex mb={20} direction={"column"}>
				<Collapse
					style={{ flex: "1 1 auto" }}
					in={opened}
					transitionDuration={250}
					transitionTimingFunction="ease-in-out"
				>
					<Title order={2} mb={10} mt={10} style={{ textAlign: "center" }}>
						{T("projectDetails", lang)}
					</Title>
					<Card shadow="sm" className={classes.card}>
						<Grid>
							<Grid.Col span="content">
								<Flex
									h={"100%"}
									direction={"column"}
									align={"center"}
									justify={"start"}
									p={10}
									key={T("projectType", lang)}
									className={classes.item}
								>
									<img
										src="/assets/octagon-exclamation-svgrepo-com.svg"
										alt="projectType"
										width={30}
										height={30}
										style={{
											filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
										}}
									/>
									<Text size="xs" mt={10}>
										{projectType && projectType.name && projectType.name[lang]}
									</Text>
								</Flex>
							</Grid.Col>
							<Grid.Col span="content">
								<Flex
									h={"100%"}
									direction={"column"}
									align={"center"}
									justify={"start"}
									p={10}
									key="ID"
									className={classes.item}
								>
									<img
										src="/assets/id-button-svgrepo-com.svg"
										alt="ID"
										width={30}
										height={30}
										style={{
											filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
										}}
									/>
									<Text size="xs" mt={10}>
										{ID}
									</Text>
								</Flex>
							</Grid.Col>
							<Grid.Col span="content">
								{startDate && (
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("startDate", lang)}
										className={classes.item}
									>
										<img
											src="/assets/calendar-arrow-up-svgrepo-com.svg"
											alt="start date"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{startDate.toDateString()}
										</Text>
									</Flex>
								)}
							</Grid.Col>
							{endDate && (
								<Grid.Col span="content">
									{endDate && (
										<Flex
											h={"100%"}
											direction={"column"}
											align={"center"}
											justify={"start"}
											p={10}
											key={T("endDate", lang)}
											className={classes.item}
										>
											<img
												src="/assets/calendar-arrow-down-svgrepo-com.svg"
												alt="end date"
												width={30}
												height={30}
												style={{
													filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
												}}
											/>
											<Text size="xs" mt={10}>
												{endDate.toDateString()} ( {T("workingDays", lang)} {workingDays} )
											</Text>
										</Flex>
									)}
								</Grid.Col>
							)}
							{priority !== undefined ||
								(priority !== null && (
									<Grid.Col span="content">
										<Flex
											h={"100%"}
											direction={"column"}
											align={"center"}
											justify={"start"}
											p={10}
											key={T("priority", lang)}
											className={classes.item}
										>
											<img
												src="/assets/security-priority-svgrepo-com.svg"
												alt="priority"
												width={30}
												height={30}
												style={{
													filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
												}}
											/>
											<Text size="xs" mt={10}>
												{priority === 0
													? T("high", lang)
													: priority === 1
														? T("medium", lang)
														: priority === 2
															? T("low", lang)
															: T("undefined", lang)}
											</Text>
										</Flex>
									</Grid.Col>
								))}
							{voltage && (
								<Grid.Col span="content">
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("voltage", lang)}
										className={classes.item}
									>
										<img
											src="/assets/voltage-svgrepo-com.svg"
											alt="voltage"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{voltage && voltage.name && voltage.name[lang]}
										</Text>
									</Flex>
								</Grid.Col>
							)}
							{community && (
								<Grid.Col span="content">
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("community", lang)}
										className={classes.item}
									>
										<img
											src="/assets/community-svgrepo-com.svg"
											alt="community"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{community && community.name && community.name[lang]}
										</Text>
									</Flex>
								</Grid.Col>
							)}
							{city && (
								<Grid.Col span="content">
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("city", lang)}
										className={classes.item}
									>
										<img
											src="/assets/city-buildings-svgrepo-com.svg"
											alt="city"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{city && city.name && city.name[lang]}
										</Text>
									</Flex>
								</Grid.Col>
							)}
							{village && (
								<Grid.Col span="content">
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("village", lang)}
										className={classes.item}
									>
										<img
											src="/assets/village-svgrepo-com.svg"
											alt="village"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{village && village.name && village.name[lang]}
										</Text>
									</Flex>
								</Grid.Col>
							)}
							{address && (
								<Grid.Col span="content">
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("address", lang)}
										className={classes.item}
									>
										<img
											src="/assets/address-svgrepo-com.svg"
											alt="address"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{address}
										</Text>
									</Flex>
								</Grid.Col>
							)}
							{branch && (
								<Grid.Col span="content">
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("branch", lang)}
										className={classes.item}
									>
										<img
											src="/assets/branch-svgrepo-com.svg"
											alt="branch"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{branch && branch.name && branch.name[lang]}
										</Text>
									</Flex>
								</Grid.Col>
							)}
							{/* <Grid.Col span="content">
								<Flex
									h={"100%"}
									direction={"column"}
									align={"center"}
									justify={"start"}
									p={10}
									key={T("substation", lang)}
									className={classes.item}
								>
									<img
										src="/assets/bolt-svgrepo-com.svg"
										alt="substation"
										width={30}
										height={30}
										style={{
											filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
										}}
									/>
									<Text size="xs" mt={10}>
										{substation}
									</Text>
								</Flex>
							</Grid.Col> */}
							{orderNumber && (
								<Grid.Col span="content">
									<Flex
										h={"100%"}
										direction={"column"}
										align={"center"}
										justify={"start"}
										p={10}
										key={T("substation", lang)}
										className={classes.item}
									>
										<img
											src="/assets/number-sign-110-svgrepo-com.svg"
											alt="orderNumber"
											width={30}
											height={30}
											style={{
												filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
											}}
										/>
										<Text size="xs" mt={10}>
											{orderNumber}
										</Text>
									</Flex>
								</Grid.Col>
							)}
							<Grid.Col span="content">
								<Flex
									h={"100%"}
									direction={"column"}
									align={"center"}
									justify={"start"}
									p={10}
									key={T("projectType", lang)}
									className={classes.item}
								>
									<img
										src="/assets/old-man-1-svgrepo-com.svg"
										alt="voltage"
										width={30}
										height={30}
										style={{
											filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
										}}
									/>
									<Text size="xs" mt={10}>
										{oldProject ? T("oldProject", lang) : T("newProject", lang)}
									</Text>
								</Flex>
							</Grid.Col>
							<Grid.Col span="content">
								<Flex
									h={"100%"}
									direction={"column"}
									align={"center"}
									justify={"start"}
									p={10}
									key={T("projectType", lang)}
									className={classes.item}
								>
									<img
										src="/assets/dollar1-svgrepo-com.svg"
										alt="voltage"
										width={30}
										height={30}
										style={{
											filter: colorScheme === "dark" ? "invert(1)" : "invert(0)"
										}}
									/>
									<Text size="xs" mt={10}>
										Payments
									</Text>
								</Flex>
							</Grid.Col>
							<Grid.Col span={12}>
								<Flex h={160}>
									<ScrollArea h={"100%"} w={"100%"}>
										<SimpleGrid cols={3} spacing="xs" verticalSpacing="xs">
											{files?.map((file, key) => {
												return (
													<div style={{ position: "relative" }} key={key}>
														<embed
															type="application/pdf"
															width={"100%"}
															height={"140px"}
															src={`${config.api.API_URL}/${file.path}`}
															style={{
																borderRadius: "5px",
																marginBottom: "10px"
															}}
															onClick={(e) => {
																open(true);
																setModalStartIndex(files.indexOf(file));
															}}
														/>
														{!fullScreen && (
															<>
																<Button
																	onClick={() => removeFile({ id: file._id })}
																	color="red"
																	style={{
																		position: "absolute",
																		top: 5,
																		right: 5,
																		opacity: 0.8,
																		width: "20px",
																		height: "20px",
																		padding: "0"
																	}}
																>
																	<CloseIcon
																		size={14}
																		color={colorScheme === "dark" ? "white" : "black"}
																		m={0}
																	/>
																</Button>

																<Button
																	onClick={(e) => {
																		open(true);
																		setModalStartIndex(files.indexOf(file));
																	}}
																	color="cyan"
																	style={{
																		position: "absolute",
																		bottom: 5,
																		right: 5,
																		opacity: 0.8,
																		width: "30px",
																		height: "30px",
																		padding: "0"
																	}}
																>
																	<IconShare2
																		size={14}
																		color={colorScheme === "dark" ? "white" : "black"}
																		m={0}
																	/>
																</Button>
															</>
														)}
													</div>
												);
											})}
										</SimpleGrid>
										<FileInput
											variant="filled"
											placeholder={T("addFiles", lang)}
											onChange={(files) => handleSave(files, lang, "files")}
											leftSection={
												<IconPictureInPictureOn
													style={{ width: rem(18), height: rem(18) }}
													stroke={1.5}
												/>
											}
											multiple={true}
											style={{
												position: "absolute",
												bottom: 10,
												left: 10,
												color: "var(--mantine-color-blue-6)"
											}}
										/>
									</ScrollArea>
									<Modal opened={opened3} onClose={close} centered fullScreen>
										<ReactImageGallery
											startIndex={modalStartIndex}
											slideDuration={200}
											showThumbnails={false}
											items={files?.map((file, key) => {
												file.renderItem = () => {
													return (
														<embed
															type="application/pdf"
															key={key}
															style={{
																width: "100%",
																height: "calc(100vh - 100px)"
															}}
															src={`${config.api.API_URL}/${file.path}`}
														/>
													);
												};
												return file;
											})}
											onScreenChange={(fullScreen) => {
												if (fullScreen) {
													document.body.style.overflow = "hidden";
													setFullScreen(true);
												} else {
													document.body.style.overflow = "auto";
													setFullScreen(false);
												}
											}}
										/>
									</Modal>
								</Flex>
							</Grid.Col>
							<Grid.Col span={12}>
								<CardSection p={20} mt={20}>
									<Group justify="center" grow>
										{
											<>
												<Button
													leftSection={
														<IconDeviceFloppy
															size={14}
															color={colorScheme === "dark" ? "white" : "black"}
														/>
													}
													variant="light"
													onClick={() => {
														saveItem();
													}}
												>
													{T("save", lang)}
												</Button>
											</>
										}
									</Group>
								</CardSection>
							</Grid.Col>
						</Grid>
					</Card>
				</Collapse>
				<Collapse
					style={{ flex: "1 1 auto" }}
					in={opened2}
					transitionDuration={250}
					transitionTimingFunction="ease-in-out"
				>
					<Title order={2} mb={10} mt={10} style={{ textAlign: "center" }}>
						{localSteps[step - 1]?.name[lang]}
					</Title>
					<Card shadow="sm" className={classes.card}>
						<Stepper
							localSteps={localSteps}
							activeStep={activeStep}
							handleStepChange={handleStepChange}
							modelSendToServer={modelSendToServer}
							step={step}
							lang={lang}
							user={user}
							permissions={permissions}
							toggleInner={ToggleInner}
							openedInner={openedInner}
						/>
					</Card>
				</Collapse>
			</Flex>
			{(user.ananunaki || permissions.find((permission) => permission.name === "step1")?.read) &&
			step === 1 ? (
				<Step1
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 2 ? (
				<Step2
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 3 ? (
				<Step3
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 4 ? (
				<Step4
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 5 ? (
				<Step5
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 6 ? (
				<Step6
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
					disabledSteps={disabledSteps}
					setDisabledSteps={setDisabledSteps}
				/>
			) : step === 7 ? (
				<Step7
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 8 ? (
				<Step8
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 9 ? (
				<Step9
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 10 ? (
				<Step10
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 11 ? (
				<Step11
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 12 ? (
				<Step12
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 13 ? (
				<Step13
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 14 ? (
				<Step14
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 15 ? (
				<Step15
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 16 ? (
				<Step16
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 17 ? (
				<Step17
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 18 ? (
				<Step18
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 19 ? (
				<Step19
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 20 ? (
				<Step20
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 21 ? (
				<Step21
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
					disabledSteps={disabledSteps}
					setDisabledSteps={setDisabledSteps}
				/>
			) : step === 22 ? (
				<Step22
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 23 ? (
				<Step23
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 24 ? (
				<Step24
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 25 ? (
				<Step25
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 26 ? (
				<Step26
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 27 ? (
				<Step27
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 28 ? (
				<Step28
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 29 ? (
				<Step29
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 30 ? (
				<Step30
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 31 ? (
				<Step31
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 32 ? (
				<Step32
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 33 ? (
				<Step33
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 34 ? (
				<Step34
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 35 ? (
				<Step35
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 36 ? (
				<Step36
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 37 ? (
				<Step37
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 38 ? (
				<Step38
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 39 ? (
				<Step39
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 40 ? (
				<Step40
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 41 ? (
				<Step41
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 42 ? (
				<Step42
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 43 ? (
				<Step43
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 44 ? (
				<Step44
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : step === 45 ? (
				<Step45
					index={step}
					id={id}
					modelSendToServer={modelSendToServer}
					setBusy={setBusy}
					isBusy={isBusy}
					ID={ID}
					setID={setID}
					history={history}
					lang={lang}
					step={step}
					steps={steps}
					setStep={setStep}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
					handleStepChange={handleStepChange}
					route={route}
					openedInner={openedInner}
					localSteps={localSteps}
					UpdateStep={UpdateStep}
					priority={priority}
					setPriority={setPriority}
					projectStartDate={startDate}
					setProjectStartDate={setStartDate}
					projectEndDate={endDate}
					setProjectEndDate={setEndDate}
					projectType={projectType}
					projectTypes={projectTypes}
					setProjectType={setProjectType}
					setProjectTypes={setProjectTypes}
					voltage={voltage}
					branch={branch}
					city={city}
					village={village}
					community={community}
					permissions={permissions}
				/>
			) : (
				<div>Step {step} is not found</div>
			)}
		</>
	);
}
