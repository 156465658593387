import React, { useEffect, useState } from "react";
import config from "../../config";
import { useUser } from "../../helpers/userContext";
import CustomInput from "./input";
import {
	Input,
	useMantineColorScheme,
	useMantineTheme,
	MantineProvider,
	Button,
	Box,
	Flex,
	Menu,
	Text,
	Title,
	Table,
	Checkbox,
	Card
} from "@mantine/core";
import { useLang } from "../../helpers/language";
import Select from "react-select";

import {
	MRT_GlobalFilterTextInput,
	MRT_ToggleFiltersButton,
	MantineReactTable,
	useMantineReactTable
} from "mantine-react-table";
import { IconUserCircle, IconSend, IconEdit, IconDownload } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useDidUpdate } from "@mantine/hooks";

export function PermissionsTableComponent({
	value = [],
	setter,
	columns,
	data,
	placeholder,
	disabled,
	key
}) {
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();
	const { user } = useUser();

	const customStyles = {
		control: (baseStyles, state) => ({
			...baseStyles,
			background: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
			border: `1px solid ${colorScheme === "dark" ? Theme.colors.dark[4] : Theme.colors.dark[1]}`,
			fontSize: Theme.fontSizes.sm,
			boxShadow: "none",
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9]
		}),
		menu: (baseStyles, state) => ({
			...baseStyles,
			background: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
			zIndex: 1000,
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9]
		}),
		option: (baseStyles, state) => ({
			...baseStyles,
			backgroundColor: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9]
		}),
		singleValue: (baseStyles, state) => ({
			...baseStyles,
			background: "transparent",
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9]
		})
	};

	const handleChange = (row, input, crud) => {
		data.map((item) => {
			if (item._id === row._id) {
				item.inputs.map((inp) => {
					if (inp._id === input._id) {
						if (crud === "update" && !input.read) {
							let state = !input[crud];
							input["update"] = state;
							input["read"] = state;
						} else if (crud === "update" && input.read) {
							input[crud] = !input[crud];
						} else if (crud === "create" && !input.read) {
							let state = !input[crud];
							input["create"] = state;
							input["read"] = state;
						} else if (crud === "create" && input.read) {
							input[crud] = !input[crud];
						} else if (crud === "delete" && !input.read) {
							let state = !input[crud];
							input["delete"] = state;
							input["read"] = state;
						} else if (crud === "delete" && input.read) {
							input[crud] = !input[crud];
						} else if (crud === "read" && input.update && input.read) {
							let state = !input[crud];
							input["create"] = state;
							input["read"] = state;
							input["update"] = state;
							input["delete"] = state;
						} else {
							input[crud] = !input[crud];
						}
					}
				});
			}
		});
		setter([...data]);
	};

	let renderDetailPanel = ({ row }) => {
		return (
			<>
				<Table mt={10} mb={10} ml={"4.2vw"} mr={30}>
					<Table.Thead>
						<Table.Tr>
							<Table.Th>Name</Table.Th>
							<Table.Th>Create</Table.Th>
							<Table.Th>Read</Table.Th>
							<Table.Th>Update</Table.Th>
							<Table.Th>Delete</Table.Th>
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody>
						{row.original?.inputs?.map((input, index) => {
							return (
								<Table.Tr key={index} mt={5} mb={5}>
									<Table.Td>{input.name}</Table.Td>
									<Table.Td>
										{"create" in input && (
											<Checkbox
												checked={input.create}
												onChange={(e) => handleChange(row.original, input, "create")}
											/>
										)}
									</Table.Td>
									<Table.Td>
										<Checkbox
											checked={input.read}
											onChange={(e) => handleChange(row.original, input, "read")}
										/>
									</Table.Td>
									<Table.Td>
										<Checkbox
											checked={input.update}
											onChange={(e) => handleChange(row.original, input, "update")}
										/>
									</Table.Td>
									<Table.Td>
										{"delete" in input && (
											<Checkbox
												checked={input.delete}
												onChange={(e) => handleChange(row.original, input, "delete")}
											/>
										)}
									</Table.Td>
								</Table.Tr>
							);
						})}
					</Table.Tbody>
				</Table>
			</>
		);
	};

	const table = useMantineReactTable({
		columns,
		data: data ? data : [],
		renderDetailPanel,
		enableSorting: false,
		enableColumnActions: false,
		positionToolbarAlertBanner: "bottom",
		initialState: {
			pagination: {
				pageSize: 100
			}
		}
	});
	return (
		<MantineProvider key={key}>
			<MantineReactTable table={table} />
		</MantineProvider>
	);
}
