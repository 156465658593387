import React from "react";
import { ListPage } from "../../ListPage";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import config from "../../../config";
import { T } from "../../../helpers/translator";

export default function Rooms() {
	const { lang } = useLang();
	const columns = [
		{
			accessorKey: "index",
			permissionModel: "index",
			header: T("index", lang),
			enableClickToCopy: true,
			size: 30,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = row.index;
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row?.name}`,
			permissionModel: "name",
			header: T("name", lang),
			grow: true,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name ? row.name : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		}
	];
	return (
		<ListPage
			route={config.api.room}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"room"}
			uploadType={true}
			limit={null}
			columns={columns}
		/>
	);
}
