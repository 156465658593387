import { Title, Text, Button, Container, Group } from "@mantine/core";
import classes from "./NotFoundTitle.module.css";
import { useLang } from "../helpers/language";
import { T } from "../helpers/translator";

export default function ErrorPage() {
	const lang = useLang();
	return (
		<Container className={classes.root}>
			<div className={classes.label}>404</div>
			<Title className={classes.title}>{T("errorPageTitle", lang)}</Title>
			<Text c="dimmed" size="lg" ta="center" className={classes.description}>
				{T("errorPageText", lang)}
			</Text>
			<Group justify="center">
				<Button variant="subtle" size="md">
					{T("errorpageButton", lang)}
				</Button>
			</Group>
		</Container>
	);
}
