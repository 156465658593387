import React from "react";
import { ListPage } from "../../ListPage";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { T } from "../../../helpers/translator";

export default function Problems() {
	const { lang } = useLang();

	const columns = [
		{
			accessorKey: "index",
			permissionModel: "index",
			header: T("index", lang),
			enableClickToCopy: true,
			size: 30,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = row.index;
				return result;
			}
		},
		{
			accessorFn: (row) => `${row?.name.en} ${row?.name.ru} ${row?.name.hy}`,
			permissionModel: "name",
			header: T("name", lang),
			enableClickToCopy: true,
			grow: true,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name[lang] ? row.name[lang] : "...");
				return result;
			}
		},
		{
			accessorKey: "relatedList",
			header: T("relatedList", lang),
			permissionModel: "relatedList",
			enableClickToCopy: true,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.relatedList ? row.relatedList : "...");
				return result;
			}
		}
	];
	return (
		<ListPage
			route={config.api.problem}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"problem"}
			uploadType={null}
			limit={null}
			columns={columns}
		/>
	);
}
