import React, { useState, useEffect, useMemo } from "react";
import SinglePage from "../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress from "nprogress";

export default function ChatChannelList() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.chat;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const [index, setIndex] = useState("");

	const [users, setUsers] = useState([]);
	const [allUsers, setAllUsers] = useState([]);

	const [parentChatId, setParentChatId] = useState("");
	const [privateChat, setPrivate] = useState(false);
	const [readOnly, setReadOnly] = useState(false);
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");

	const [messages, setMessages] = useState([]);
	// optimizing for search
	const [messageCount, setMessageCount] = useState(0);
	const [lastMessage, setLastMessage] = useState("");
	const [usernames, setUsernames] = useState([]);

	const modelSendToServer = {
		index,
		users,
		parentChatId,
		privateChat,
		readOnly,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		},
		messagesCount: messageCount,
		lastMessage,
		usernames
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setAllUsers(rawUsers);

			// get current chat messages
			let rawMessages = await API.get({
				route: config.api.message,
				query: {
					chatId: id
				}
			});
			if (rawMessages) setMessages(rawMessages);

			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					populate: ["users"]
				});
				setIndex(currentItem[0]?.index);
				setMessages(currentItem[0]?.messages);
				setUsers(currentItem[0]?.users);
				setPrivate(currentItem[0]?.privateChat);
				setAllUsers(rawUsers);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	console.log("messages", messages);

	if (isBusy) return <Loading />;
	else return <>chat messages</>;
}
