import cx from "clsx";
import { ActionIcon, useMantineColorScheme, useComputedColorScheme, Group } from "@mantine/core";
import { IconSun, IconMoon } from "@tabler/icons-react";
import classes from "./ActionToggle.module.css";

export function ActionToggle() {
	const { setColorScheme } = useMantineColorScheme();
	const computedColorScheme = useComputedColorScheme("light", {
		getInitialValueInEffect: true
	});

	return (
		<Group justify="center">
			<ActionIcon
				onClick={() => {
					document.documentElement.dir = computedColorScheme === "light" ? "dark" : "light";
					setColorScheme(computedColorScheme === "light" ? "dark" : "light");
				}}
				variant="default"
				size="xl"
				aria-label="Toggle color scheme"
			>
				{computedColorScheme === "dark" && (
					<IconSun className={cx(classes.icon, classes.light)} stroke={1.5} />
				)}
				{computedColorScheme === "light" && (
					<IconMoon className={cx(classes.icon, classes.dark)} stroke={1.5} />
				)}
			</ActionIcon>
		</Group>
	);
}
