import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useUser } from "../../../helpers/userContext";
import parse from "html-react-parser";
import { useLang } from "../../../helpers/language";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Community() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.community;
	const { lang } = useLang();

	const [index, setIndex] = useState("");
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");
	const [branches, setBranches] = useState([]);
	const [allBranches, setAllBranches] = useState([]);
	const [cities, setCities] = useState([]);
	const [allCities, setAllCities] = useState([]);
	const [villages, setVillages] = useState([]);
	const [allVillages, setAllVillages] = useState([]);
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	const columns = [
		{
			accessorFn: (row) => `${row.name?.en} ${row.name?.ru} ${row.name?.hy}`,
			header: T("name", lang),
			filterVariant: "multi-select",
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name?.[lang] ? row.name?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		}
	];
	const modelSendToServer = {
		index,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		},
		branches,
		cities,
		villages
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			let allUsedCities = [];
			let allUsedVillages = [];

			try {
				// all branches
				let rawBranches = await API.get({ route: config.api.branch });
				if (rawBranches) setAllBranches(rawBranches);
			} catch (error) {
				console.log(error);
			}

			try {
				// get all community villages
				let allCommunities = await API.get({
					route: config.api.community
				});

				allCommunities.forEach((community) => {
					community.cities.forEach((city) => {
						allUsedCities.push(city);
					});
					community.villages.forEach((village) => {
						allUsedVillages.push(village);
					});
				});
			} catch (error) {
				console.log(error);
			}

			try {
				// all cities
				let rawCities = await API.get({
					route: config.api.city
				});
				if (rawCities) {
					rawCities = rawCities.filter((city) => !allUsedCities.includes(city._id));
					setAllCities(rawCities);
				}
			} catch (error) {
				console.log(error);
			}

			try {
				// all villages
				let rawVillages = await API.get({
					route: config.api.village
				});
				if (rawVillages) {
					rawVillages = rawVillages.filter((village) => !allUsedVillages.includes(village._id));
					setAllVillages(rawVillages);
				}
			} catch (error) {
				console.log(error);
			}

			// community model is {
			// 	index: Number,
			// 	name: {
			// 		en: String,
			// 		ru: String,
			// 		hy: String,
			// 	},
			// 	branches: [{ type: Schema.Types.ObjectId, ref: 'branch_model' }],
			// 	cities: [{ type: Schema.Types.ObjectId, ref: 'city_model' }],
			// 	villages: [{ type: Schema.Types.ObjectId, ref: 'village_model' }],
			// 	temporary: Boolean,
			// }

			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					populate: ["branches", "cities", "villages"]
				});
				setIndex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);
				setBranches(currentItem[0]?.branches);
				setCities(currentItem[0]?.cities);
				setVillages(currentItem[0]?.villages);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "community") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel="community"
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						lang: "en",
						permissionModel: "name"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInRussian", lang),
						lang: "ru",
						permissionModel: "name"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInArmenian", lang),
						lang: "hy",
						permissionModel: "name"
					}
				]}
				cartComponent={{
					title: T("branchesCitiesVillages", lang),
					data: [
						{
							placeholder: T("branch", lang),
							value: branches,
							setter: setBranches,
							data: allBranches,
							columns: columns,
							width: "220px",
							quantity: "off",
							permissionModel: "branches"
						},
						{
							placeholder: T("city", lang),
							value: cities,
							setter: setCities,
							data: allCities,
							columns: columns,
							width: "220px",
							quantity: "off",
							permissionModel: "cities"
						},
						{
							placeholder: T("village", lang),
							value: villages,
							setter: setVillages,
							data: allVillages,
							columns: columns,
							width: "220px",
							quantity: "off",
							permissionModel: "villages"
						}
					]
				}}
			/>
		);
}
