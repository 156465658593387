import React from "react";
import { ListPage } from "../../ListPage";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import moment from "moment";
import { T } from "../../../helpers/translator";

export default function Roles() {
	const { lang } = useLang();

	const columns = [
		{
			accessorKey: "index",
			permissionModel: T("index", lang),
			header: "Index",
			enableClickToCopy: true,
			size: 30,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.index ? row.index : "...");
				return result;
			}
		},
		{
			accessorFn: (row) => `${row?.name?.en} ${row?.name?.ru} ${row?.name?.hy}`,
			permissionModel: "name",
			header: T("name", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row?.name?.[lang] ? row.name?.[lang] : "...");
				return result;
			}
		},
		{
			accessorKey: "updatedAt",
			permissionModel: "updatedAt",
			header: "Updated At",
			enableClickToCopy: true,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = moment(row.updatedAt).format("DD.MM.YYYY");
				return result;
			}
		},
		{
			accessorFn: (row) => `${row?.desc?.en} ${row?.desc?.ru} ${row?.desc?.hy}`,
			header: T("desc", lang),
			permissionModel: "desc",
			grow: true,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row?.desc?.[lang] ? row.desc?.[lang] : "...");
				return result;
			}
		}
	];

	return (
		<ListPage
			route={config.api.role}
			// query={}
			sorting={{ ip: "asc" }}
			permissionModel={"role"}
			uploadType={true}
			limit={null}
			columns={columns}
		/>
	);
}
