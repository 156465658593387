import React, { useEffect, useState } from "react";
import {
	useMantineColorScheme,
	useMantineTheme,
	MantineProvider,
	Button,
	Flex,
	Text
} from "@mantine/core";
import { useLang } from "../../helpers/language";
import Select from "react-select";
import { T } from "../../helpers/translator";

import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import { IconDownload } from "@tabler/icons-react";

export function CartComponent({
	value = [],
	setter,
	columns,
	index,
	data = [],
	placeholder,
	disabled,
	crud = {
		read: false,
		create: false,
		update: false,
		delete: false
	},
	user = {
		ananunaki: false
	}
}) {
	const [selectedValue, setSelectedValue] = useState([]);
	const [dataWithoutValues, setDataWithoutValues] = useState([]);
	const { lang } = useLang();
	const [defaultVal, setDefaultVal] = useState({ value: "", label: "" });
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();

	function onSelectChange(val) {
		setSelectedValue(val);
		setDefaultVal(val);
	}

	useEffect(() => {
		if (data.length > 0 && value && value.length > 0) {
			// loop with break statement
			let temp = [];
			for (let i = 0; i < value.length; i++) {
				for (let j = 0; j < data.length; j++) {
					if (value[i]._id === data[j]._id) {
						temp.push(data[j]);
						break;
					}
				}
			}
			setDataWithoutValues(data.filter((item) => !temp.includes(item)));
		} else {
			setDataWithoutValues(data);
		}
	}, [data, value]);

	const formatOptionLabel = ({ name, _id }) => {
		return <div key={_id}>{name?.[lang]}</div>;
	};

	const customStyles = {
		control: (baseStyles) => ({
			...baseStyles,
			background: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
			border: `1px solid ${colorScheme === "dark" ? Theme.colors.dark[4] : Theme.colors.dark[1]}`,
			fontSize: Theme.fontSizes.sm,
			boxShadow: "none",
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9]
		}),
		menu: (baseStyles) => ({
			...baseStyles,
			background: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9]
		}),
		menuList: (baseStyles) => ({
			...baseStyles,
			height: "220px"
		}),

		option: (baseStyles) => ({
			...baseStyles,
			backgroundColor: colorScheme === "dark" ? Theme.colors.dark[6] : Theme.white,
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9],
			zIndex: 1000,
			innerHeight: "40px",
			outerHeight: "70px"
		}),
		singleValue: (baseStyles) => ({
			...baseStyles,
			background: "transparent",
			color: colorScheme === "dark" ? Theme.white : Theme.colors.dark[9]
		})
	};

	function addToList() {
		setter(value ? [...value, selectedValue] : [selectedValue]);
		setSelectedValue([]);
		setDataWithoutValues(
			value ? dataWithoutValues.filter((item) => item._id !== selectedValue._id) : dataWithoutValues
		);
		setDefaultVal({ value: "", label: "" });
	}

	const table = useMantineReactTable({
		columns,
		data: value,
		enableRowSelection: true,
		paginationDisplayMode: "pages",
		positionToolbarAlertBanner: "bottom",
		mantinePaginationProps: {
			radius: "xl",
			size: "lg"
		},
		renderTopToolbar: ({ table }) => {
			const handleDeactivate = () => {
				setter(
					value.filter(
						(item, index) => !Object.keys(table.getState().rowSelection).includes(index.toString())
					)
				);
				setSelectedValue([selectedValue, ...value]);
				table.toggleAllRowsSelected(false);
			};
			return (
				<Flex p="md" justify="space-between" key={index}>
					<Flex>
						<div style={{ width: "360px", zIndex: 99 }}>
							<Select
								options={dataWithoutValues ? dataWithoutValues : []}
								onChange={onSelectChange}
								value={defaultVal}
								formatOptionLabel={formatOptionLabel}
								placeholder={placeholder}
								styles={customStyles}
								isDisabled={disabled}
							/>
						</div>
						{(user.ananunaki || crud.update) && (
							<Button
								leftSection={<IconDownload size={14} />}
								variant="default"
								onClick={addToList}
								ml={10}
								disabled={!selectedValue || selectedValue?.length < 1}
							>
								{T("addToList", lang)}
							</Button>
						)}
					</Flex>

					<Text size="sm" weight={500}>
						{placeholder}
					</Text>

					{(user.ananunaki || crud.delete) && (
						<Flex>
							<Button
								color="red"
								disabled={!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
								onClick={handleDeactivate}
								variant="filled"
							>
								{T("removeFromList", lang)}
							</Button>
						</Flex>
					)}
				</Flex>
			);
		}
	});

	if (user?.ananunaki || crud?.read) {
		return (
			<div style={{ marginBottom: "20px" }}>
				<MantineProvider>
					<MantineReactTable table={table} />
				</MantineProvider>
			</div>
		);
	}
	return null;
}
