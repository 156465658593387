import React, { useState } from "react";
import { Flex, Paper, useMantineColorScheme } from "@mantine/core";
import {
	IconArrowForward,
	IconMessage2,
	IconPinned,
	IconStar,
	IconTrash
} from "@tabler/icons-react";
import ActionsMenuButton from "./ActionsMenuButton";

const ActionsMenu = ({
	onReply = () => {},
	onForward = () => {},
	onDelete = () => {},
	onStarred = () => {},
	onPinned = () => {},
	pinned = false,
	starred = false
}) => {
	const { colorScheme } = useMantineColorScheme();
	const [starredState, setStarredState] = useState(starred);
	const [pinnedState, setPinnedState] = useState(pinned);
	return (
		<>
			<Paper
				shadow="xs"
				p="sm"
				withBorder
				style={{
					borderRadius: 4,
					position: "absolute",
					left: "100%",
					top: 0,
					transform: "translate(-100%, -50%)"
				}}
			>
				<Flex gap="sm">
					<ActionsMenuButton
						icon={
							<IconMessage2
								size={20}
								stroke={1.5}
								color={`${colorScheme !== "dark" ? "#212121" : "#ffffff"}`}
							/>
						}
						onClick={onReply}
						text="Reply"
					/>
					<ActionsMenuButton
						icon={
							<IconArrowForward
								size={20}
								stroke={1.5}
								color={`${colorScheme !== "dark" ? "#212121" : "#ffffff"}`}
							/>
						}
						onClick={onForward}
						text="Forward"
					/>
					<ActionsMenuButton
						icon={
							<IconPinned
								size={20}
								stroke={1.5}
								color={`${colorScheme !== "dark" ? "#212121" : "#ffffff"}`}
								fill={pinnedState ? "orange" : "transparent"}
							/>
						}
						onClick={() => {
							onPinned();
							setPinnedState(!pinnedState);
						}}
						text="Pin"
					/>
					<ActionsMenuButton
						icon={
							<IconStar
								size={20}
								stroke={1.5}
								color={`${colorScheme !== "dark" ? "#212121" : "#ffffff"}`}
								fill={starredState ? "orange" : "transparent"}
							/>
						}
						onClick={() => {
							onStarred();
							setStarredState(!starredState);
						}}
						text="Star"
					/>
					<ActionsMenuButton
						icon={
							<IconTrash
								size={20}
								stroke={1.5}
								color={`${colorScheme !== "dark" ? "#212121" : "#ffffff"}`}
							/>
						}
						onClick={onDelete}
						text="Delete"
					/>
				</Flex>
			</Paper>
		</>
	);
};

export default ActionsMenu;
