import { useContext, createContext } from "react";
import {
	Flex,
	Button
} from "@mantine/core";

export const Language = createContext();

export function useLang() {
	return useContext(Language);
}

const data = [
	{
		label: "English",
		image: <span className="fi fi-us"></span>,
		value: "en"
	},
	{
		label: "Русский",
		image: <span className="fi fi-ru"></span>,
		value: "ru"
	},
	{
		label: "Հայերեն",
		image: <span className="fi fi-am"></span>,
		value: "hy"
	}
];

export function LanguagePicker() {
	const { lang, setLang } = useLang();
	return (
		<Flex wrap="nowrap" mt={1} direction={"row"}>
			{data.map((item) => (
				<Button
					justify="center"
					width
					value={item.value}
					onClick={() => setLang(item.value)}
					m={2}
					leftSection={item.image}
					variant={lang === item.value ? "light" : "default"}
				>
					{item.label}
				</Button>
			))}
		</Flex>
	);
}
