import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import { useUser } from "../../../helpers/userContext";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Status() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.status;
	const { lang } = useLang();
	const { user } = useUser();

	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");
	const [permissions, setPermissions] = useState([]);

	const [index, setIndex] = useState("");
	const [href, sethref] = useState("");

	// const [image, setImage] = useState("");

	const modelSendToServer = {
		index,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		},
		href
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					}
				});
				setIndex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);
				sethref(currentItem[0]?.href);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissionModel="status"
				permissions={permissions}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("inputNumber", lang),
						permissionModel: "index"
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "name",
						lang: "en"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInRussian", lang),
						permissionModel: "name",
						lang: "ru"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInArmenian", lang),
						permissionModel: "name",
						lang: "hy"
					},
					{
						value: href,
						setter: sethref,
						type: "text",
						usageType: "textfield",
						label: T("href", lang),
						placeholder: T("href", lang),
						permissionModel: "name"
					}
				]}
			/>
		);
}
