import React, { useEffect, useState } from "react";
import config from "../../config";
import CustomInput from "./input";
import {
	Input,
	useMantineColorScheme,
	useMantineTheme,
	MantineProvider,
	Button,
	Box,
	Flex,
	Menu,
	Text,
	Title
} from "@mantine/core";
import { useLang } from "../../helpers/language";
import Select from "react-select";

import {
	MRT_GlobalFilterTextInput,
	MRT_ToggleFiltersButton,
	MantineReactTable,
	useMantineReactTable
} from "mantine-react-table";
import { IconUserCircle, IconSend, IconEdit, IconDownload } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { useDidUpdate } from "@mantine/hooks";

export function TableComponent({
	value = [],
	setter,
	columns,
	data,
	placeholder,
	disabled,
	key,
	crud,
	user
}) {

	const table = useMantineReactTable({
		columns,
		data,
		// enableRowSelection: true,
		// paginationDisplayMode: "pages",
		positionToolbarAlertBanner: "bottom"
		// mantinePaginationProps: {
		//   radius: "xl",
		//   size: "lg",
		// },
	});

	if (user.ananunaki || crud.read) {
		return (
			<MantineProvider key={key}>
				<MantineReactTable table={table} />
			</MantineProvider>
		);
	}
	return null;
}
