import React from "react";
import { ListPage } from "../../ListPage";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import config from "../../../config";
import { T } from "../../../helpers/translator";

export default function CommunityList() {
	const { lang } = useLang();
	const columns = [
		{
			accessorFn: (row) => `${row.name.en} ${row.name.ru} ${row.name.hy}`,
			permissionModel: "name",
			header: T("name", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name[lang] ? row.name[lang] : "...");
				return result;
			}
		},
		{
			accessorFn: (row) =>
				`${row?.branches[0]?.name?.en} ${row?.branches[0]?.name?.ru} ${row?.branches[0]?.name?.hy}`,
			permissionModel: "branches",
			header: T("branch", lang),
			grow: true,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(
					row?.branches[0]?.name[lang]
						? row?.branches.length > 0
							? row.branches[0].name[lang]
							: row.branches[0].name[lang] + "..."
						: T("noContent", lang)
				);
				return result;
			}
		}
	];
	return (
		<ListPage
			route={config.api.community}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"community"}
			uploadType={true}
			limit={null}
			populate={["branches"]}
			columns={columns}
		/>
	);
}
