import NProgress from "nprogress";
import { useEffect } from "react";

export default function Loading({ progress }) {
	useEffect(() => {
		NProgress.start();
		return () => {
			NProgress.done();
		};
	}, []);

	return null;
}
