import React, { useState, useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { ChromePicker } from "react-color";
import Swal from "sweetalert2";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import config from "../../config";
import {
	Input,
	PasswordInput,
	NumberInput,
	useMantineColorScheme,
	Card,
	Textarea,
	Select,
	Radio,
	Group,
	Switch,
	Text,
	Flex,
	Button,
	ActionIcon,
	Checkbox,
	FileInput
} from "@mantine/core";
import dayjs from "dayjs";
import { DateInput } from "@mantine/dates";
import {
	IconAt,
	IconPhone,
	IconWorldWww,
	IconCalendar,
	IconBuildingBank,
	IconIdBadge2,
	IconSend,
	IconPinned
} from "@tabler/icons-react";
import { IMaskInput } from "react-imask";
// import Select from "react-select";

import { RichTextEditor } from "@mantine/tiptap";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import { set } from "nprogress";
import { IconPaperclip } from "@tabler/icons-react";

let lastTheme = null;
let lightEditorTiming = (Math.random() * 100).toString() + 1;
let darkEditorTiming = (Math.random() * 100).toString();
let timeout = null;

const CustomInput = ({
	value,
	usageType,
	setter,
	apiurl,
	api,
	language,
	type,
	id,
	label,
	disabled,
	placeholder,
	optionListValue,
	radioValue,
	defaultValue,
	priceSetter,
	settimeInterval,
	searchable = false,
	clearable = true,
	trim,
	tolowercase,
	serverCall,
	trimStart,
	description,
	leftIcon,
	rightIcon,
	crud,
	user,
	textAreaProps = {},
	attachment = { files: [], setter: () => {} }
}) => {
	const textAreaDefaultProps = {
		toolbar: true,
		bold: true,
		italic: true,
		underline: true,
		strikethrough: true,
		code: false,
		alignLeft: false,
		alignCenter: false,
		alignRight: false,
		alignJustify: false,
		blockquote: false,
		h1: false,
		h2: false,
		h3: false,
		h4: false,
		hr: false,
		bulletList: false,
		orderedList: false,
		subscript: false,
		superscript: false,
		link: false,
		unlink: false,
		clearFormatting: false,
		highlight: false,
		undo: false,
		redo: false,
		submit: false
	};
	const mergedTextAreaProps = { ...textAreaDefaultProps, ...textAreaProps };
	const [showColorPicker, setShowColorPicker] = useState(false);
	const { lang } = useLang();
	const { colorScheme } = useMantineColorScheme();

	const fileInputRef = useRef(null);

	const handleChange = async (e) => {
		let value = e.target?.value;
		if (trim) {
			value = value.trim();
		}
		if (tolowercase) value = value.toLowerCase();
		if (trimStart) value = value.trimStart();
		if (serverCall && api && apiurl) {
			// Init a timeout variable to be used below
			clearTimeout(timeout);
			timeout = setTimeout(async function () {
				if (type === "email") {
					let res = await api.get(apiurl, {
						email: value
					});
					if (res[0]) {
						Swal.fire(config.swal.emailIsBusy[language]);
						setter("");
					}
				} else {
					let res = await api.get(apiurl, {
						username: value
					});
					if (res[0]) {
						Swal.fire(config.swal.usernameIsBusy[language]);
						setter("");
					}
				}
			}, 1000);
		}
		setter(value);
	};

	// const handlePriceChange = (e) => {
	// 	priceSetter(e?.target?.value);
	// };

	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Superscript,
			SubScript,
			TextAlign.configure({ types: ["heading", "paragraph"] })
		],
		editable: !disabled,
		onUpdate({ editor }) {
			if (editor.getHTML() === "<p></p>") {
				editor.commands.setContent("");
				setter("");
			} else {
				setter(editor.getHTML());
			}
		},
		content: value
	});

	const clearEditor = () => {
		editor?.commands?.setContent("");
	};

	const handleTimeIntervalChange = (e) => {
		settimeInterval(e?.target?.value);
	};

	const handleTextFieldChange = (data) => {
		setter(data);
	};

	const handleColorChange = (color) => {
		setter(color.hex);
	};

	const toggleShowColorPicker = () => {
		setShowColorPicker(!showColorPicker);
	};

	// Function to handle file selection
	const handleFileChange = (event) => {
		const files = Array.from(event.target.files);
		if (files.length > 0) {
			// Handle file upload or display preview here
			attachment.setter([...attachment.files, ...files]);
		}
	};

	// Function to trigger the file input dialog
	const handleAttachFileClick = () => {
		fileInputRef?.current?.click();
	};

	const editorRef = useRef(null);

	if ((crud && crud?.update) || user?.ananunaki) {
		if (usageType === "textfield") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						disabled={disabled}
						leftSection={leftIcon ? leftIcon : null}
						rightSection={rightIcon ? rightIcon : null}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "idCard") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						disabled={disabled}
						leftSection={<IconIdBadge2 size={16} />}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "bank") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						disabled={disabled}
						leftSection={<IconBuildingBank size={16} />}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "email") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						leftSection={<IconAt size={16} />}
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "phone") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						leftSection={<IconPhone size={16} />}
						component={IMaskInput}
						mask="+374 (00) 00-00-00"
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "password") {
			return (
				<Input.Wrapper label={label}>
					<PasswordInput
						placeholder={placeholder}
						id="your-password"
						value={value || ""}
						onChange={handleChange}
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "textarea") {
			return (
				<RichTextEditor editor={editor}>
					{mergedTextAreaProps.toolbar && (
						<RichTextEditor.Toolbar
							sticky
							stickyOffset={60}
							style={{ justifyContent: "space-between" }}
						>
							<RichTextEditor.ControlsGroup>
								{mergedTextAreaProps.bold && <RichTextEditor.Bold />}
								{mergedTextAreaProps.italic && <RichTextEditor.Italic />}
								{mergedTextAreaProps.underline && <RichTextEditor.Underline />}
								{mergedTextAreaProps.strikethrough && <RichTextEditor.Strikethrough />}
								{mergedTextAreaProps.clearFormatting && <RichTextEditor.ClearFormatting />}
								{mergedTextAreaProps.code && <RichTextEditor.Code />}
							</RichTextEditor.ControlsGroup>

							<RichTextEditor.ControlsGroup>
								{mergedTextAreaProps.h1 && <RichTextEditor.H1 />}
								{mergedTextAreaProps.h2 && <RichTextEditor.H2 />}
								{mergedTextAreaProps.h3 && <RichTextEditor.H3 />}
								{mergedTextAreaProps.h4 && <RichTextEditor.H4 />}
								{mergedTextAreaProps.hr && <RichTextEditor.Hr />}
							</RichTextEditor.ControlsGroup>

							<RichTextEditor.ControlsGroup>
								{mergedTextAreaProps.blockquote && <RichTextEditor.Blockquote />}
								{mergedTextAreaProps.hr && <RichTextEditor.Hr />}
								{mergedTextAreaProps.bulletList && <RichTextEditor.BulletList />}
								{mergedTextAreaProps.orderedList && <RichTextEditor.OrderedList />}
								{mergedTextAreaProps.subscript && <RichTextEditor.Subscript />}
								{mergedTextAreaProps.superscript && <RichTextEditor.Superscript />}
							</RichTextEditor.ControlsGroup>

							<RichTextEditor.ControlsGroup>
								{mergedTextAreaProps.link && <RichTextEditor.Link />}
								{mergedTextAreaProps.unlink && <RichTextEditor.Unlink />}
							</RichTextEditor.ControlsGroup>

							<RichTextEditor.ControlsGroup>
								{mergedTextAreaProps.alignLeft && <RichTextEditor.AlignLeft />}
								{mergedTextAreaProps.alignCenter && <RichTextEditor.AlignCenter />}
								{mergedTextAreaProps.alignRight && <RichTextEditor.AlignRight />}
								{mergedTextAreaProps.alignJustify && <RichTextEditor.AlignJustify />}
								{mergedTextAreaProps.highlight && <RichTextEditor.Highlight />}
							</RichTextEditor.ControlsGroup>

							<RichTextEditor.ControlsGroup>
								{mergedTextAreaProps.undo && <RichTextEditor.Undo />}
								{mergedTextAreaProps.redo && <RichTextEditor.Redo />}
							</RichTextEditor.ControlsGroup>
							{mergedTextAreaProps.submit && (
								<RichTextEditor.ControlsGroup>
									<ActionIcon
										variant="filled"
										aria-label="Send"
										onClick={() => {
											mergedTextAreaProps.submit();
											editor?.commands?.setContent("");
										}}
										gradient={{ from: "blue", to: "cyan", deg: 90 }}
										disabled={!value || disabled}
									>
										<IconSend stroke={2} size={18} />
									</ActionIcon>
								</RichTextEditor.ControlsGroup>
							)}
							<RichTextEditor.ControlsGroup>
								<Button
									onClick={handleAttachFileClick}
									style={{ background: "none", border: "none", cursor: "pointer" }}
								>
									<IconPaperclip
										size={20}
										stroke={1.5}
										color={`${colorScheme !== "dark" ? "#212121" : "#ffffff"}`}
									/>
								</Button>
							</RichTextEditor.ControlsGroup>
						</RichTextEditor.Toolbar>
					)}
					{/* Hidden file input to trigger the file selection */}
					<input
						type="file"
						ref={fileInputRef}
						onChange={handleFileChange}
						style={{ display: "none" }}
						multiple
					/>

					<RichTextEditor.Content />
				</RichTextEditor>
			);
		} else if (usageType === "textarea-tiny") {
			let lightEdotor = (
				<Input.Wrapper label={label}>
					<Editor
						id={lightEditorTiming}
						key={lightEditorTiming}
						disabled={disabled}
						onInit={(evt, editor) => (editorRef.current = editor)}
						init={{
							height: "50vh",
							width: "100%",
							strict_loading_mode: true,
							statusbar: false,
							branding: false,
							placeholder: placeholder,
							fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
							menubar: "file edit insert view format table",
							plugins: [
								"charmap advlist autolink lists link image charmap print preview anchor",
								"searchreplace visualblocks code fullscreen",
								"insertdatetime media table paste code help wordcount code"
							],
							formatpainter_blacklisted_formats:
								"link,address,removeformat,formatpainter_removeformat",
							toolbar:
								// eslint-disable-next-line
								"charmap | undo redo | formatselect | bold italic backcolor | \
									alignleft aligncenter alignright alignjustify | \
									bullist numlist outdent indent | removeformat | help"
						}}
						value={value}
						onEditorChange={handleTextFieldChange}
					/>
				</Input.Wrapper>
			);
			let darkEditor = (
				<Input.Wrapper label={label}>
					<Editor
						id={darkEditorTiming}
						key={darkEditorTiming}
						disabled={disabled}
						onInit={(evt, editor) => (editorRef.current = editor)}
						init={{
							skin: "oxide-dark",
							content_css: "dark",
							height: "50vh",
							width: "100%",
							strict_loading_mode: true,
							statusbar: false,
							branding: false,
							placeholder: placeholder,
							fontsize_formats: "8px 10px 12px 14px 18px 24px 36px",
							menubar: "file edit insert view format table",
							plugins: [
								"charmap advlist autolink lists link image charmap print preview anchor",
								"searchreplace visualblocks code fullscreen",
								"insertdatetime media table paste code help wordcount code"
							],
							formatpainter_blacklisted_formats:
								"link,address,removeformat,formatpainter_removeformat",
							toolbar:
								// eslint-disable-next-line
								"charmap | undo redo | formatselect | bold italic backcolor | \
									alignleft aligncenter alignright alignjustify | \
									bullist numlist outdent indent | removeformat | help"
						}}
						value={value}
						onEditorChange={handleTextFieldChange}
					/>
				</Input.Wrapper>
			);
			if (colorScheme === "light" && lastTheme === "light") return lightEdotor;
			else if (colorScheme === "dark" && lastTheme === "dark") return darkEditor;
			else if (colorScheme === "light" && lastTheme === "dark") {
				lastTheme = colorScheme;
				return lightEdotor;
			} else if (colorScheme === "dark" && lastTheme === "light") {
				lastTheme = colorScheme;
				return darkEditor;
			} else if (!lastTheme) {
				lastTheme = colorScheme;
				if (colorScheme === "light") return lightEdotor;
				else return darkEditor;
			}
		} else if (usageType === "optionList") {
			if (optionListValue && Array.isArray(optionListValue)) {
				return (
					<Input.Wrapper label={label}>
						<Select
							placeholder={placeholder}
							disabled={disabled}
							searchable={searchable}
							clearable={clearable}
							allowDeselect={false}
							data={optionListValue.flatMap((item) => {
								let label =
									(typeof item.label?.[lang] === "string" && item.label?.[lang]) ||
									(typeof item.label === "string" && item.label) ||
									item.name?.[lang] ||
									item.title?.[lang] ||
									item.title ||
									item.name?.toString() ||
									"no data provided";
								let value =
									item._id ||
									item.value ||
									item.name?.[lang] ||
									item.title?.[lang] ||
									item.name ||
									item.title ||
									"no data provided";
								return { label, value };
							})} // data should be an array of objects with label and value
							value={Array.isArray(value) ? value[0] : value}
							onChange={setter}
							nothingFoundMessage="Nothing found..."
							defaultValue={defaultValue}
						/>
					</Input.Wrapper>
				);
			}
			return null;
		} else if (usageType === "switch") {
			return (
				<Flex direction={"column"} align={"center"}>
					<Text>{label}</Text>
					<Switch
						checked={value}
						mt={10}
						value={value}
						onChange={() => {
							setter(!value);
						}}
					/>
				</Flex>
			);
		} else if (usageType === "datePicker") {
			let localValue;
			if (value) {
				localValue = JSON.parse(JSON.stringify(value));
				localValue = new Date(localValue);
			} else {
				localValue = null;
			}
			return (
				<DateInput
					value={localValue}
					onChange={setter}
					valueFormat="DD/MM/YYYY"
					label={label}
					clearable
					placeholder={placeholder}
					leftSection={<IconCalendar size={16} />}
					disabled={disabled}
				/>
			);
		} else if (usageType === "number") {
			return (
				<NumberInput
					label={label}
					description={description}
					placeholder={placeholder}
					value={value || 0}
					onChange={(val) => setter(val)}
					disabled={disabled}
				/>
			);
		} else if (usageType === "url") {
			return (
				<Input.Wrapper label={label}>
					<Input
						placeholder={placeholder}
						onChange={handleChange}
						value={value || ""}
						leftSection={<IconWorldWww size={16} />}
						component={IMaskInput}
						mask="https://www.***************************************************"
						disabled={disabled}
					/>
				</Input.Wrapper>
			);
		} else if (usageType === "boolean") {
			return (
				<div key={id} className="form-element">
					<label htmlFor={label}>{label}</label>
					{value ? (
						<p>
							<i className="fas fa-thumbs-up"></i>
						</p>
					) : (
						<p>
							<i className="fas fa-exclamation-circle"></i>
						</p>
					)}
				</div>
			);
		} else if (usageType === "color-picker") {
			return (
				<div key={id} className="form-element">
					<label htmlFor={label}>{label}</label>
					<div className="input-group">
						<div className="input-group-btn">
							<button className="btn btn-info btn-flat" onClick={toggleShowColorPicker}>
								Color Picker
							</button>
						</div>
						{/* <p >color test</p> */}
						<input
							value={value || ""}
							onChange={handleChange}
							type={type}
							className="form-control"
							id={label}
							placeholder={placeholder}
						/>
						<div className="input-group-btn">
							<button className="btn btn-flat" style={{ backgroundColor: value }}>
								Color Example
							</button>
						</div>
					</div>
					{showColorPicker && <ChromePicker onChange={handleColorChange} />}
				</div>
			);
		} else if (usageType === "radio") {
			return (
				<Input.Wrapper label={label} key={new Date().getTime()}>
					<Radio.Group
						name={label}
						onChange={(e) => {
							console.log("🚀 ~ e:", e);
							setter(e);
						}}
					>
						<Group mt="xs">
							{radioValue && radioValue.length > 0
								? radioValue.map((val, index) => {
										return (
											<Radio
												key={index}
												value={val.value}
												label={val.label}
												checked={val.value === value}
											/>
										);
									})
								: null}
						</Group>
					</Radio.Group>
				</Input.Wrapper>
			);
		} else if (usageType === "checkbox") {
			return (
				<Input.Wrapper label={label}>
					<Group mt="xs">
						<Checkbox
							checked={value}
							onChange={(e) => {
								setter(e.target.checked);
							}}
						/>
					</Group>
				</Input.Wrapper>
			);
		} else {
			return <h3 key={id}>There is no content to show in input</h3>;
		}
	} else {
		return (
			<Input.Wrapper label={label}>
				<Input
					placeholder={placeholder}
					value={
						usageType === "password"
							? "********"
							: usageType === "textarea"
								? parse(value ? value : "")
								: usageType === "idCard"
									? value || ""
									: usageType === "bank"
										? value || ""
										: usageType === "email"
											? value || ""
											: usageType === "phone"
												? "+374" + value || ""
												: usageType === "textarea-simple"
													? parse(value ? value : "")
													: usageType === "datePicker"
														? value
															? dayjs(value).format("DD/MM/YYYY")
															: ""
														: usageType === "number"
															? value || ""
															: usageType === "url"
																? value || ""
																: usageType === "textfield"
																	? parse(value ? value : "")
																	: usageType === "optionList"
																		? value?.name?.[lang] ||
																			value?.label?.[lang] ||
																			value?.label ||
																			value?.name ||
																			value
																			? optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.name?.[lang] ||
																				optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.name ||
																				optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.label?.[lang] ||
																				optionListValue.filter(
																					(item) => item.value === value || item._id === value
																				)[0]?.label ||
																				"no data provided"
																			: value || "no data provided"
																		: ""
					}
					disabled={true}
					leftSection={leftIcon ? leftIcon : null}
					rightSection={rightIcon ? rightIcon : null}
				/>
			</Input.Wrapper>
		);
	}
};

export default CustomInput;
