import React from "react";
import { ListPage } from "../../ListPage";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { Card } from "@mantine/core";
import { T } from "../../../helpers/translator";

export default function Routers() {
	const { lang } = useLang();

	const columns = [
		{
			accessorFn: (row) => `${row?.pc?.name}`,
			permissionModel: "name",
			header: T("name", lang),
			enableClickToCopy: true,

			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name ? row.name : "...");
				return result.length > 30 ? result.slice(0, 30) + "..." : result;
			}
		},
		{
			accessorKey: "ip",
			permissionModel: "ip",
			header: "IP",
			enableClickToCopy: true,
			size: 130,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.ip ? row.ip : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorKey: "speed",
			permissionModel: "speed",
			header: T("speed", lang),
			enableClickToCopy: true,
			size: 130,
			filterVariant: "select",
			mantineFilterSelectProps: {
				data: [...config.routerSpeeds.flatMap((rot) => rot.value)]
			},
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.speed ? row.speed : "...");
				return result;
			}
		},
		{
			accessorKey: "ports",
			permissionModel: "port",
			header: T("ports", lang),
			enableClickToCopy: true,
			size: 50,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				return row.ports;
			}
		},
		{
			accessorFn: (row) => `${row?.room?.name}`,
			permissionModel: "room",
			header: T("room", lang),
			size: 100,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row?.room?.name ? row.room.name : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorKey: "working",
			permissionModel: "working",
			header: T("workingState", lang),
			size: 160,
			filterVariant: "select",
			mantineFilterSelectProps: {
				data: [
					{ label: `${T("working", lang)}`, value: "true" },
					{ label: `${T("notWorking", lang)}`, value: "false" }
				]
			},
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				return row.working ? (
					<Card c="green" size="xs">
						{T("working", lang)}
					</Card>
				) : (
					<Card c="red" size="xs">
						{T("notWorking", lang)}
					</Card>
				);
			}
		},
		{
			accessorKey: "placed",
			permissionModel: "placed",
			header: T("placedState", lang),
			size: 160,
			filterVariant: "select",
			mantineFilterSelectProps: {
				data: [
					{ label: `${T("placed", lang)}`, value: "true" },
					{ label: `${T("notPlaced", lang)}`, value: "false" }
				]
			},
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				return row.placed ? (
					<Card c="green" size="xs">
						{T("placed", lang)}
					</Card>
				) : (
					<Card c="red" size="xs">
						{T("notPlaced", lang)}
					</Card>
				);
			}
		}
	];
	return (
		<ListPage
			route={config.api.router}
			query={{}}
			sorting={{ index: "desc" }}
			permissionModel={"router"}
			uploadType={true}
			limit={null}
			populate={["room"]}
			columns={columns}
		/>
	);
}
