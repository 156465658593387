import React, { useState, useEffect, useMemo } from "react";
import SinglePage from "../SinglePage";
import { useHistory } from "react-router-dom";
import * as API from "../../helpers/api";
import config from "../../config";
import Loading from "../modules/loading";
import { useUser } from "../../helpers/userContext";
import moment from "moment";
import parse from "html-react-parser";
import { useLang } from "../../helpers/language";
import NProgress from "nprogress";
import { Card, Text, Select, Button } from "@mantine/core";
import { T } from "../../helpers/translator";

export default function ChatDirect() {
	const [isBusy, setBusy] = useState(true);
	const route = config.api.chat;
	const { lang } = useLang();
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);
	let history = useHistory();

	const [index, setIndex] = useState("");
	const [partner, setpartner] = useState([]);
	const [allUsers, setAllUsers] = useState([]);
	const [messages, setMessages] = useState([]);
	const [privateChat, setPrivate] = useState(false);
	// optimizing for search

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawUsers = await API.get({ route: config.api.user });
			if (rawUsers) setAllUsers(rawUsers);

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<Card withBorder radius="md">
				{/* seelct user from list and continue, use  */}
				<Text align="center" size="xl">
					{T("selectChatPartner", lang)}
					<Select
						placeholder={T("selectChatPartner", lang)}
						searchable
						allowDeselect={false}
						data={allUsers?.flatMap((item) => ({
							label:
								item.name?.[lang] ||
								item.label?.[lang] ||
								item.label ||
								item.name ||
								"no data provided 2",
							value:
								item._id ||
								item.value ||
								item.name[lang] ||
								item.title[lang] ||
								item.name ||
								item.title ||
								"no data provided"
						}))} // data should be an array of objects with label and value
						value={partner}
						onChange={(data) => {
							// add user to users
							setpartner(data);
						}}
						nothingFoundMessage="Nothing found..."
					/>
					{/* button to cotinue to chat page */}
					<Button
						onClick={() => {
							history.push(`/${route}/direct/${partner}`);
						}}
						variant="light"
					>
						{T("continue", lang)}
					</Button>
				</Text>
			</Card>
		);
}
