import { useContext, createContext, useEffect, useState } from "react";

export const IsMobile = createContext();

export function useMobile() {
	return useContext(IsMobile);
}

export function IsMobileProvider({ children }) {
	const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return <IsMobile.Provider value={isMobile}>{children}</IsMobile.Provider>;
}
