import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import { useUser } from "../../../helpers/userContext";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Printer() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.printer;
	const { lang } = useLang();
	const { user } = useUser();

	const [index, setIndex] = useState("");
	const [name, setName] = useState("");

	const [ip, setIP] = useState("");
	const [mac, setMac] = useState("");
	const [printedCount, setprintedCount] = useState("");

	const [rooms, setRooms] = useState("");
	const [room, setRoom] = useState("");

	const [pcs, setPcs] = useState("");
	const [pc, setPc] = useState("");

	const [image, setImage] = useState("");
	const [file, setFile] = useState("");
	const [permissions, setPermissions] = useState([]);
	const [working, setWorking] = useState(true);
	const [placed, setPlaced] = useState(true);

	const modelSendToServer = {
		index,
		name,
		ip,
		mac,
		room,
		pc,
		printedCount,
		working,
		placed
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			let rawRooms = await API.get({ route: config.api.room });
			if (rawRooms) {
				setRooms(rawRooms);
			}
			let rawPCs = await API.get({ route: config.api.pc });
			if (rawPCs) {
				setPcs(rawPCs);
			}
			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					limit: 1
				});
				setIndex(currentItem[0]?.index);
				setName(currentItem[0]?.name);
				setIP(currentItem[0]?.ip);
				setMac(currentItem[0]?.mac);
				setRoom(currentItem[0]?.room);
				setPc(currentItem[0]?.pc);
				setprintedCount(currentItem[0]?.printedCount);
				setWorking(currentItem[0]?.working);
				setPlaced(currentItem[0]?.placed);

				setImage(undefined);
				currentItem[0]?.uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					if (item.destiny === "file") setFile(item);
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				permissionModel="printer"
				id={id}
				permissions={permissions}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: name,
						setter: setName,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "name"
					},
					{
						value: ip,
						setter: setIP,
						type: "text",
						usageType: "textfield",
						label: T("ipAddress", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "ip"
					},
					{
						value: mac,
						setter: setMac,
						type: "text",
						usageType: "textfield",
						label: T("macAddress", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "mac"
					},
					{
						value: printedCount,
						setter: setprintedCount,
						type: "text",
						usageType: "textfield",
						label: T("printedCount", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "printedCount"
					},
					{
						value: room,
						setter: setRoom,
						optionListValue: rooms,
						type: "optionList",
						usageType: "optionList",
						label: T("room", lang),
						permissionModel: "room"
					},
					{
						value: pc,
						setter: setPc,
						optionListValue: pcs,
						type: "optionList",
						usageType: "optionList",
						label: T("pc", lang),
						permissionModel: "pc"
					},
					{
						value: working,
						setter: setWorking,
						type: "switch",
						usageType: "switch",
						switchValue: [
							{
								value: true,
								label: T("working", lang)
							},
							{
								value: false,
								label: T("notWorking", lang)
							}
						],
						label: T("working", lang),
						permissionModel: "working"
					},
					{
						value: placed,
						setter: setPlaced,
						type: "switch",
						usageType: "switch",
						switchValue: [
							{
								value: true,
								label: T("placed", lang)
							},
							{
								value: false,
								label: T("notPlaced", lang)
							}
						],
						label: T("placed", lang),
						permissionModel: "placed"
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						acceptedFiles: ["image/*"],
						buttonAddText: "Add",
						buttonOpenText: "Open",
						buttonRemoveText: "Remove",
						dropzoneText: "There is no file, click to upload a new one",
						adminMode: user.role !== "editor",
						permissionModel: "image"
					},
					{
						destiny: "file",
						file: file,
						setFile: setFile,
						filesUploadLimit: 1,
						acceptedFiles: ["application/*"],
						buttonAddText: "Add",
						buttonOpenText: "Open",
						buttonRemoveText: "Remove",
						dropzoneText: "There is no file, click to upload a new one",
						adminMode: user.role !== "editor",
						permissionModel: "file"
					}
				]}
			/>
		);
}
