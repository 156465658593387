import React from "react";
import { useHistory } from "react-router-dom";
import { useLang } from "../../../helpers/language";
import config from "../../../config";
import {
	Group,
	Button,
	Tabs,
	Card,
	Grid,
	useMantineColorScheme,
	useMantineTheme,
	CardSection
} from "@mantine/core";
import { CREATE } from "../../../helpers/CRUD";
//! import modules
import Input from "../../modules/input";
import "date-fns";
import { useUser } from "../../../helpers/userContext";
import { IconArrowBack, IconDeviceFloppy, IconList, IconPhoto } from "@tabler/icons-react";
import classes from "../../Tabs.module.css";
import { FilesComponent } from "../../modules/filesComponent";
import { T } from "../../../helpers/translator";

export default function AddPage({
	modelSendToServer,
	inputs,
	route,
	setBusy,
	filesComponent,
	permissionModel,
	id,
	permissions,
	canSendState
}) {
	const { lang } = useLang();
	const { user } = useUser();
	const history = useHistory();
	const { colorScheme } = useMantineColorScheme();
	const Theme = useMantineTheme();

	const HandlePermissionCheck = (input) => {
		if (input) {
			if (user?.ananunaki) {
				input.crud = {
					create: true,
					read: true,
					update: true,
					delete: true
				};
				return input;
			}
			let foundedPermission = user.role?.permissions?.find(
				(permission) => permission.name === permissionModel
			);
			if (foundedPermission) {
				let foundedInput = foundedPermission.inputs?.find(
					(inp) => inp.name === input?.permissionModel
				);
				if (foundedInput && foundedInput.read) {
					input.crud = {
						create: foundedInput.create !== undefined ? foundedInput.create : false,
						read: foundedInput.read !== undefined ? foundedInput.read : false,
						update: foundedInput.update !== undefined ? foundedInput.update : false,
						delete: foundedInput.delete !== undefined ? foundedInput.delete : false
					};
					return input;
				}
			} else {
				console.error("Permission not found");
			}
		}
	};

	return (
		<Tabs defaultValue="first" variant="unstyled" classNames={classes}>
			<Tabs.List>
				<Tabs.Tab value="first" color="blue" leftSection={<IconList size={14} />}>
					{T("mainInformation", lang)}
				</Tabs.Tab>{" "}
				{filesComponent ? (
					<Tabs.Tab value="second" color="orange" leftSection={<IconPhoto size={14} />}>
						{filesComponent.length === 1 && filesComponent[0].placeholder
							? filesComponent[0].placeholder
							: T("files", lang)}
					</Tabs.Tab>
				) : null}
			</Tabs.List>
			{inputs && (
				<Tabs.Panel value="first">
					<Card
						shadow="sm"
						p="md"
						style={{
							borderTopLeftRadius: 0
						}}
					>
						<Grid
							p={20}
							style={{
								background: colorScheme === "dark" ? Theme.colors.dark[6] : ""
							}}
						>
							{inputs.map((input, index) => {
								let checkedInput = HandlePermissionCheck(input);
								if (checkedInput) {
									if (checkedInput?.lang) {
										if (checkedInput.lang === lang) {
											return (
												<Grid.Col
													key={index}
													span={{
														base: 12,
														md: checkedInput.width ? checkedInput.width : 6,
														lg: checkedInput.width ? checkedInput.width : 3
													}}
												>
													<checkedInput
														key={index}
														id={index}
														label={checkedInput.label}
														field={checkedInput.field}
														placeholder={checkedInput.placeholder}
														price={checkedInput.price}
														priceSetter={checkedInput.priceSetter}
														timeInterval={checkedInput.timeInterval}
														settimeInterval={checkedInput.settimeInterval}
														value={checkedInput.value}
														width={checkedInput.width}
														setter={checkedInput.setter}
														type={checkedInput.type}
														lang={checkedInput.lang}
														usageType={checkedInput.usageType}
														optionListValue={checkedInput.optionListValue}
														radioValue={checkedInput.radioValue}
														disabled={checkedInput.disabled}
														user={user}
														crud={checkedInput.crud}
														selectSomething={checkedInput.selectSomething}
													/>
												</Grid.Col>
											);
										}
									} else {
										return (
											<Grid.Col
												key={index}
												span={{
													base: 12,
													md: input.width ? input.width : 6,
													lg: input.width ? input.width : 3
												}}
											>
												<Input
													key={index}
													id={index}
													label={checkedInput.label}
													field={checkedInput.field}
													placeholder={checkedInput.placeholder}
													price={checkedInput.price}
													priceSetter={checkedInput.priceSetter}
													timeInterval={checkedInput.timeInterval}
													settimeInterval={checkedInput.settimeInterval}
													value={checkedInput.value}
													width={checkedInput.width}
													setter={checkedInput.setter}
													type={checkedInput.type}
													lang={checkedInput.lang}
													usageType={checkedInput.usageType}
													optionListValue={checkedInput.optionListValue}
													radioValue={checkedInput.radioValue}
													disabled={checkedInput.disabled}
													user={user}
													crud={checkedInput.crud}
													selectSomething={checkedInput.selectSomething}
												/>
											</Grid.Col>
										);
									}
								}
								return null;
							})}
						</Grid>

						<CardSection p={20} mt={20}>
							<Group justify="center" grow>
								{user?.role !== undefined && canSendState ? (
									<>
										<Button
											leftSection={
												<IconDeviceFloppy
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											variant="light"
											onClick={async () => {
												try {
													await CREATE({
														modelSendToServer,
														route,
														history,
														setBusy,
														lang,
														user,
														filesComponent,
														notification: {
															date: new Date(),
															importance: "high",
															singleSocket: true,
															route: `${config.api.API_URL}/${id}`,
															createdByUser: user._id,
															targetUsers: [
																{
																	read: false,
																	user: "624df5886e52ad23320dcfc0"
																},
																{
																	read: false,
																	user: "6252dd215a77432f90d41a2b"
																},
																{
																	read: false,
																	user: "6249c92d3f30fbdca3628432"
																}
															]
														}
													});
												} catch (error) {
													console.error(error);
												}
											}}
										>
											{T("save", lang)}
										</Button>

										<Button
											leftSection={
												<IconArrowBack
													size={14}
													color={colorScheme === "dark" ? "white" : "black"}
												/>
											}
											color="orange"
											variant="light"
											onClick={() => history.goBack()}
										>
											{T("cancel", lang)}
										</Button>
									</>
								) : null}
							</Group>
						</CardSection>
					</Card>
				</Tabs.Panel>
			)}
			{filesComponent && (
				<Tabs.Panel value="second">
					<Card shadow="sm" p="md">
						<Grid
							p={20}
							m={0}
							style={{
								backgroundColor: colorScheme === "dark" ? Theme.colors.dark[6] : ""
							}}
						>
							{filesComponent.map((component, index) => {
								let checkedComponent = HandlePermissionCheck(component);
								if (checkedComponent) {
									return (
										<Grid.Col span={{ base: 12, md: 6 }} key={index}>
											<FilesComponent
												key={index}
												id={id}
												destiny={checkedComponent.destiny}
												file={checkedComponent.file}
												filesUploadLimit={checkedComponent.filesUploadLimit}
												acceptedFiles={checkedComponent.acceptedFiles}
												lang={checkedComponent.lang}
												alt={checkedComponent.alt}
												objectToAttachAnUpload={checkedComponent.objectToAttachAnUpload}
												setAlt={checkedComponent.setAlt}
												setBusy={setBusy}
												setFile={checkedComponent.setFile}
												setFiles={checkedComponent.setFiles}
												route={route}
												adminMode={checkedComponent.adminMode}
												thumbSizeWidth={checkedComponent.thumbSizeWidth}
												thumbSizeHeight={checkedComponent.thumbSizeHeight}
												responsiveImages={checkedComponent.responsiveImages}
												width={checkedComponent.width}
												height={checkedComponent.height}
												filesMaxSize={checkedComponent.filesMaxSize}
												user={user}
												crud={checkedComponent.crud}
												permissionModel={checkedComponent.permissionModel}
												permissions={permissions}
											/>
										</Grid.Col>
									);
								}
								return null;
							})}
						</Grid>
					</Card>
				</Tabs.Panel>
			)}
		</Tabs>
	);
}
