import React from "react";
import { ListPage } from "../../ListPage";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { T } from "../../../helpers/translator";

export default function Positions() {
	const { lang } = useLang();
	const columns = [
		{
			accessorKey: "index",
			permissionModel: "index",
			header: "Index",
			size: 50,
			grow: false
		},
		{
			accessorFn: (row) => `${row.name?.en} ${row.name?.ru} ${row.name?.hy}`,
			permissionModel: "name",
			grow: true,
			header: T("name", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name?.[lang] ? row.name?.[lang] : "");
				return result;
			}
		}
	];
	return (
		<ListPage
			route={config.api.position}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"position"}
			uploadType={true}
			limit={null}
			columns={columns}
			pageSize={100}
			enableFacetedValues={true}
		/>
	);
}
