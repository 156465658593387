import React from "react";
import { Image } from "@mantine/core";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

export default function OfficeMap() {
	return (
		<TransformWrapper>
			<TransformComponent>
				<Image radius="md" src="/assets/office-map.jpg" alt="office-map" />
			</TransformComponent>
		</TransformWrapper>
	);
}
