import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLang } from "../../helpers/language";
import { T } from "../../helpers/translator";

export function InteractiveTimeComponent({ historySteps }) {
	const { lang } = useLang();
	const [timelineDuration, setTimelineDuration] = useState("");
	
	function lastTimelineItemDuration(startDate, endDate) {
		if (startDate && endDate) {
			setTimelineDuration(moment(endDate).diff(moment(startDate), "seconds"));
		} else if (startDate && !endDate) {
			setTimelineDuration(moment().diff(moment(startDate), "seconds"));
		}
	}

	useEffect(() => {
		setInterval(() => {
			lastTimelineItemDuration(
				historySteps?.[historySteps?.length - 1]?.startDate,
				historySteps?.[historySteps?.length - 1]?.endDate
			);
		}, 1000);
		return () => {
			clearInterval();
		};
	}, []);

	return (
		<>
			{timelineDuration} {T("seconds", lang)}
		</>
	);
}
