import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import { useUser } from "../../../helpers/userContext";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Room() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.room;
	const { lang } = useLang();

	const [name, setName] = useState("");
	const [index, setIndex] = useState("");
	const { user } = useUser();
	const [permissions, setPermissions] = useState([]);

	// const [image, setImage] = useState("");

	const modelSendToServer = {
		name,
		index
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					}
				});
				setIndex(currentItem[0]?.index);
				setName(currentItem[0]?.name);

				// let images = await getUploads(id, "image");
				// setImage(images && images[0]);
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				permissionModel="room"
				id={id}
				permissions={permissions}
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "index"
					},
					{
						value: name,
						setter: setName,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "name"
					}
				]}
			/>
		);
}
