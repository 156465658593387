import React, { useEffect, useState } from "react";
import { ListPage } from "../ListPage";
import config from "../../config";
import NProgress from "nprogress";
import { useUser } from "../../helpers/userContext";
import { useLang } from "../../helpers/language";
import parse from "html-react-parser";
import Loading from "../modules/loading";
import { useParams } from "react-router-dom";
import { useMantineColorScheme } from "@mantine/core";
import { T } from "../../helpers/translator";

export default function Chats() {
	const { user } = useUser();
	const { lang } = useLang();
	let { chatMode } = useParams();
	const { colorScheme } = useMantineColorScheme();
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);

	function setRefreshColumns() {
		return [
			{
				accessorKey: "index",
				permissionModel: "chat",
				header: T("index"),
				enableClickToCopy: true,
				size: 30,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = row.index ? row.index : "...";
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorFn: (row) => `${row.name?.en} ${row.name?.ru} ${row.name?.hy}`,
				size: 500,
				permissionModel: "chat",
				header: T("name"),
				// filterVariant: "multi-select",
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row.name?.[lang] ? row.name?.[lang] : "...");
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			}
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			setColumns(setRefreshColumns());
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
		// eslint-disable-next-line
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.chat + "/" + chatMode}
				query={
					chatMode === "all"
						? {}
						: chatMode === "channel"
							? {}
							: chatMode === "direct"
								? {
										$or: [{ from: user._id }, { to: { $in: [user._id] } }]
									}
								: {}
				}
				sorting={{ index: "asc" }}
				uploadType={true}
				limit={null}
				noAdd={chatMode === "all"}
				columns={columns}
				// populate={["position", "pc", "department"]}
				permissionModel={"chat"}
			/>
		);
	else return <Loading />;
}
