import React, { useEffect, useState } from "react";
import { ListPage } from "../../ListPage";
import config from "../../../config";
import * as API from "../../../helpers/api";
import NProgress from "nprogress";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import Loading from "../../modules/loading";
import moment from "moment";
import { Avatar, Box, Button, Flex, Text, Title } from "@mantine/core";
import { Link, useParams, useHistory } from "react-router-dom";
import { useMantineColorScheme } from "@mantine/core";
import {
	IconAlertTriangle,
	IconCake,
	IconMail,
	IconPhone,
	IconRecordMail,
	IconStar
} from "@tabler/icons-react";
import { T } from "../../../helpers/translator";

const parsePhoneNumber = (number) => `${number?.split(/[-*\/\s]+/).join("")}`;

function checkBirthDay(birthdate) {
	const today = moment();
	const birthdateMoment = moment(birthdate, moment.ISO_8601, true);

	if (!birthdateMoment.isValid()) {
		return "Неверная дата";
	}

	const years = today.diff(birthdateMoment, "years");
	birthdateMoment.add(years, "years");

	const isBirthday = today.isSame(birthdateMoment, "day");
	const upcomingBirthdayDays = birthdateMoment.clone().add(1, "year").diff(today, "days");

	if (isBirthday) {
		return 1;
	} else if (upcomingBirthdayDays < 30) {
		return 2;
	} else {
		return 0;
	}
}

function calculateAge(birthdate) {
	const today = moment();
	const birthdateMoment = moment(birthdate, moment.ISO_8601, true);
	if (!birthdateMoment.isValid()) {
		return "Неверная дата";
	}
	const years = today.diff(birthdateMoment, "years");
	birthdateMoment.add(years, "years");
	let formattedResult = years;
	return formattedResult;
}

const customColors = {
	titleColor: "#2ea7c1",
	textColor: "#666"
};

export default function Users() {
	const { lang } = useLang();
	let { userWorkingStatus } = useParams();
	const { colorScheme } = useMantineColorScheme();
	const [positions, setPositions] = useState([]);
	const [departments, setDepartments] = useState([]);
	const [roles, setRoles] = useState([]);
	const [isBusy, setBusy] = useState(true);
	const [columns, setColumns] = useState([]);
	const history = useHistory();

	let renderDetailPanel = ({ row }) => {
		return (
			<Box
				style={{
					display: "flex",
					justifyContent: "flex-start",
					alignItems: "center",
					gap: "16px",
					padding: "16px",
					backgroundColor: colorScheme === "dark" ? "#1A1B1E" : "#FFFFFF",
					border: `1px solid ${colorScheme === "dark" ? "#2C2E33" : "#E0E0E0"}`,
					borderRadius: "8px"
				}}
			>
				<Link to={`/user/${row.original._id}`}>
					<Avatar
						variant="filled"
						radius="sm"
						h={270}
						w={270}
						src={`${config.api.API_URL}/${row.original.uploads?.[0]?.path}`}
						style={{ border: `2px solid ${customColors.titleColor}` }}
					/>
				</Link>
				<Box sx={{ textAlign: "center" }}>
					<Flex justify="flex-center">
						<Title style={{ color: customColors.titleColor }}>{row.original.name?.[lang]}</Title>
					</Flex>
					{row.original.position?.name?.[lang] && (
						<Flex justify="start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("position", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.position?.name?.[lang]}
							</Text>
						</Flex>
					)}
					{row.original.department?.name?.[lang] && (
						<Flex justify="start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("department", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{row.original.department?.name?.[lang]}
							</Text>
						</Flex>
					)}
					{row.original?.pc?.name && (
						<Flex justify="start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("pc", lang)}
							</Text>

							<Button
								variant="subtle"
								color="blue"
								size="sm"
								ml={10}
								leftSection={<IconStar size="1.5rem" />}
								onClick={() => history.push(`/pc/${row.original.pc._id}`)}
							>
								{row.original?.pc?.name}
							</Button>
						</Flex>
					)}
					{row.original.phone && (
						<Flex justify="start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("phone", lang)}
							</Text>
							<Button
								variant="subtle"
								color="teal"
								size="sm"
								ml={10}
								leftSection={<IconPhone size="1.5rem" />}
								onClick={() => window.open(`tel:${parsePhoneNumber(row.original?.phone)}`)}
							>
								{row.original?.phone
									?.replace("+374", "0")
									.replace(/(\d{3})(\d{2})(\d{2})(\d{2})/, "$1-$2-$3-$4")}
							</Button>
						</Flex>
					)}
					{row.original.email && (
						<Flex justify="start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("email", lang)}
							</Text>
							<Button
								variant="subtle"
								color="blue"
								size="sm"
								ml={10}
								leftIcon={<IconRecordMail size="1.5rem" />}
								onClick={() => window.open(`mailto:${row.original?.email}`)}
							>
								{row.original?.email}
							</Button>
						</Flex>
					)}
					{row.original.dateOfBirth && (
						<Flex justify="start">
							<Text fw={700} lh={"34px"} style={{ color: customColors.textColor }}>
								{T("dateOfBirth", lang)}
							</Text>
							<Text fw={700} lh={"34px"} ml={20} c={"grey.6"}>
								{moment(row.original.dateOfBirth).format("MM.DD.YYYY")}
							</Text>
						</Flex>
					)}
				</Box>
			</Box>
		);
	};

	function setRefreshColumns() {
		return [
			{
				accessorKey: "index",
				permissionModel: "index",
				header: T("index", lang),
				enableClickToCopy: true,
				size: 30,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = row.index ? row.index : "...";
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorFn: (row) => ``,
				permissionModel: "firstName",
				size: 120,
				header: T("firstName", lang),
				// filterVariant: "multi-select",
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row.firstName?.[lang] ? row.firstName?.[lang] : "...");
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorFn: (row) => `${row.lastName?.en} ${row.lastName?.ru} ${row.lastName?.hy}`,
				permissionModel: "lastName",
				size: 120,
				header: T("lastName", lang),
				// filterVariant: "multi-select",
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row.lastName?.[lang] ? row.lastName?.[lang] : "...");
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorKey: "phone",
				permissionModel: "phone",
				enableColumnActions: false,
				header: T("more", lang),
				// size max width
				size: 140,

				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = "";
					result = (
						<Flex align={"center"} gap={10}>
							<Button
								variant="light"
								color="teal"
								size="xs"
								disabled={!row.phone}
								onClick={() => window.open(`tel:${parsePhoneNumber(row.phone)}`)}
							>
								<IconPhone size="1.5rem" />
							</Button>

							<Button
								variant="light"
								color="blue"
								size="xs"
								disabled={!row.email}
								onClick={() => window.open(`mailto:${row.email}`)}
							>
								<IconMail size="1.5rem" />
							</Button>
						</Flex>
					);

					return result;
				}
			},
			{
				accessorFn: (row) =>
					`${row.position?.name?.hy} ${row.position?.name?.ru} ${row.position?.name?.en}`,
				permissionModel: "position",
				header: T("position", lang),
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: [...new Set(positions.flatMap((pos) => pos.name?.[lang]))]
				},
				size: 200,
				columnSizing: true,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row?.position?.name[lang] ? row.position.name[lang] : "...");
					return result.length > 20 ? result.slice(0, 20) + "..." : result;
				}
			},
			{
				accessorFn: (row) =>
					`${row.department?.name?.hy} ${row.department?.name?.ru} ${row.department?.name?.en}`,
				permissionModel: "department",
				header: T("department", lang),
				filterVariant: "select",
				mantineFilterSelectProps: {
					data: [...new Set(departments.flatMap((dep) => dep.name?.[lang]))]
				},
				size: 300,
				columnSizing: true,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = parse(row?.department?.name[lang] ? row.department.name[lang] : "...");
					return result.length > 30 ? result.slice(0, 30) + "..." : result;
				}
			},
			{
				accessorKey: "dateOfBirth",
				permissionModel: "dateOfBirth",
				header: T("dateOfBirth", lang),
				enableClickToCopy: true,
				filterVariant: "date",
				size: 200,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = moment(row.dateOfBirth).format("MM.DD.YYYY");
					let isbirthDay = checkBirthDay(row.dateOfBirth, colorScheme);
					if (isbirthDay === 0) {
						return result;
					}
					return (
						<Flex align={"center"} gap={10}>
							<Avatar
								color={isbirthDay === 1 ? "red.6" : isbirthDay === 2 ? "teal.6" : "grey.6"}
								radius="sm"
							>
								<IconCake size="1.5rem" />
							</Avatar>
							<Text c={isbirthDay === 1 ? "red.6" : isbirthDay === 2 ? "teal.6" : "grey.6"}>
								{result}
							</Text>
						</Flex>
					);
				}
			},
			{
				accessorKey: "age",
				permissionModel: "dateOfBirth",
				header: T("age", lang),
				filterVariant: "range",
				filterFn: "between",
				size: 200,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result = calculateAge(row.dateOfBirth);
					return result;
				}
			},
			{
				accessorKey: "idCardEndDate",
				permissionModel: "idCardEndDate",
				header: T("idCardEndDate", lang),
				enableClickToCopy: true,
				size: 140,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result;
					// find out if the given date is less than one month from now
					let today = moment();
					let idCardEndDate = moment(row.idCardEndDate);
					if (row.idCardEndDate === null || !row.idCardEndDate) return "";
					let diff = idCardEndDate.diff(today, "days");
					if (diff < 30) {
						result = (
							<Flex align={"center"} gap={10}>
								<Avatar color="red.6" radius="sm">
									<IconAlertTriangle size="1.5rem" />
								</Avatar>
								<Text c="red.6">{moment(row.idCardEndDate).format("MM.DD.YYYY")}</Text>
							</Flex>
						);
					}
					return result;
				}
			},
			{
				accessorKey: "passportEndDate",
				permissionModel: "passportEndDate",
				header: T("passportEndDate", lang),
				enableClickToCopy: true,
				size: 140,
				Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
					let result;
					// find out if the given date is less than one month from now
					let today = moment();
					let passportEndDate = moment(row.passportEndDate);
					if (row.passportEndDate === null || !row.passportEndDate) return "";
					let diff = passportEndDate.diff(today, "days");
					if (diff < 30) {
						result = (
							<Flex align={"center"} gap={10}>
								<Avatar color="red.6" radius="sm">
									<IconAlertTriangle size="1.5rem" />
								</Avatar>
								<Text c="red.6">{moment(row.passportEndDate).format("MM.DD.YYYY")}</Text>
							</Flex>
						);
					}
					return result;
				}
			}
		];
	}

	useEffect(() => {
		NProgress.start();
		(async () => {
			let rawDepartments = await API.get({ route: config.api.department });
			if (rawDepartments) setDepartments(rawDepartments);
			let rawPositions = await API.get({ route: config.api.position });
			if (rawPositions) setPositions(rawPositions);
			let rawRoles = await API.get({ route: config.api.role });
			if (rawRoles) setRoles(rawRoles);

			setColumns(setRefreshColumns());

			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		setColumns(setRefreshColumns());
	}, [lang, colorScheme]);

	if (!isBusy && columns.length > 0)
		return (
			<ListPage
				route={config.api.user}
				query={
					userWorkingStatus === "all"
						? {}
						: {
								workingStatus: userWorkingStatus ? userWorkingStatus : "working"
							}
				}
				customFiltersArrays={{
					position: positions,
					department: departments,
					role: roles
				}}
				sorting={{ index: "asc" }}
				uploadType={true}
				limit={null}
				populate={["position", "pc", "department"]}
				permissionModel={"user"}
				columns={columns}
				renderDetailPanel={renderDetailPanel}
			/>
		);
	else return <Loading />;
}
