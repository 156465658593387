import React from "react";
import { ListPage } from "../../ListPage";
import config from "../../../config";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import { T } from "../../../helpers/translator";

export default function Departments() {
	const { lang } = useLang();
	const columns = [
		{
			accessorKey: "index",
			permissionModel: "index",
			header: T("index", lang),
			size: 30
		},
		{
			accessorFn: (row) => `${row.name.en} ${row.name.ru} ${row.name.hy}`,
			permissionModel: "name",
			size: 420,
			grow: true,
			header: T("name", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name[lang] ? row.name[lang] : "...");
				return result.length > 80 ? result.slice(0, 80) + "..." : result;
			}
		},
		{
			accessorFn: (row) =>
				`${row.leader?.name?.en} ${row.leader?.name?.ru} ${row.leader?.name?.hy}`,
			permissionModel: "leader",
			header: T("leader", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(
					row.leader ? (row.leader.name[lang] ? row.leader.name[lang] : "...") : "no data"
				);
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		}
	];
	return (
		<ListPage
			route={config.api.department}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"department"}
			uploadType={true}
			limit={null}
			populate={["leader"]}
			columns={columns}
			enableFacetedValues={true}
		/>
	);
}
