import React from "react";
import { ListPage } from "../../ListPage";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import config from "../../../config";
import { Card } from "@mantine/core";
import { T } from "../../../helpers/translator";

export default function Monitors() {
	const { lang } = useLang();
	const columns = [
		// index
		{
			accessorKey: "index",
			permissionModel: "index",
			header: "ID",
			size: 30
		},
		{
			accessorFn: (row) => `${row?.name}`,
			permissionModel: "name",
			header: T("name", lang),
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row?.name ? row.name : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row?.pc?.name}`,
			permissionModel: "pc",
			header: T("pc", lang),
			size: 50,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row?.pc?.name ? row.pc?.name : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorKey: "working",
			permissionModel: "working",
			header: T("workingState", lang),
			size: 160,
			filterVariant: "select",
			mantineFilterSelectProps: {
				data: [
					{ label: `${T("working", lang)}`, value: "true" },
					{ label: `${T("notWorking", lang)}`, value: "false" }
				]
			},
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				return row.working ? (
					<Card c="green" size="xs">
						{T("working", lang)}
					</Card>
				) : (
					<Card c="red" size="xs">
						{T("notWorking", lang)}
					</Card>
				);
			}
		},
		{
			accessorKey: "placed",
			permissionModel: "placed",
			header: T("placedState", lang),
			size: 160,
			filterVariant: "select",
			mantineFilterSelectProps: {
				data: [
					{ label: `${T("placed", lang)}`, value: "true" },
					{ label: `${T("notPlaced", lang)}`, value: "false" }
				]
			},
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				return row.placed ? (
					<Card c="green" size="xs">
						{T("placed", lang)}
					</Card>
				) : (
					<Card c="red" size="xs">
						{T("notPlaced", lang)}
					</Card>
				);
			}
		}
	];
	return (
		<ListPage
			route={config.api.monitor}
			query={{}}
			sorting={{ index: "asc" }}
			permissionModel={"monitor"}
			uploadType={true}
			limit={null}
			populate={["pc"]}
			columns={columns}
		/>
	);
}
