import React, { useState } from "react";
import {
	Button,
	Checkbox,
	CloseIcon,
	Flex,
	Group,
	Image,
	Modal,
	Text,
	useMantineColorScheme
} from "@mantine/core";
import moment from "moment/moment";
import ActionsMenu from "../actions-menu/ActionsMenu";
import classes from "./message.module.css";
import { useLang } from "../../../helpers/language";
import config from "../../../config";
import UserInfo from "../user-info/UserInfo";
import parse from "html-react-parser";
import { IconShare2 } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import Swal from "sweetalert2";
import { T, Translations } from "../../../helpers/translator";
import * as API from "../../../helpers/api";
import ImageGallery from "react-image-gallery";

const Message = ({
	message = {},
	user = {},
	onReply = () => {},
	onForward = () => {},
	onStarred = () => {},
	onPinned = () => {},
	onDelete = (deleteForEveryone = false) => {},
	hideActions = false,
	isBusy,
	setBusy,
	setFiles
}) => {
	const { lang } = useLang();
	const [showActions, setShowActions] = useState(false);
	const [userInfoDrawerOpen, setUserInfoDrawerOpen] = useState(false);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [deleteForEveryone, setDeleteForAll] = useState(false);
	const [fullScreen, setFullScreen] = useState(false);
	const { colorScheme } = useMantineColorScheme();
	const [opened, { open, close }] = useDisclosure(false);
	const [modalStartIndex, setModalStartIndex] = useState(0);
	var fileId = "";

	async function removeFile({ id }) {
		if (id) {
			Swal.fire({
				title: T("areYouSure", lang),
				text: T("youCannotRevertThisAction", lang),
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: T("yes", lang)
			}).then(async (result) => {
				if (result.isConfirmed) {
					if (message.uploads) {
						try {
							await API.remove(config.api.upload, id);
							setBusy(true);
							setFiles(null);
						} catch (err) {
							console.error(err);
							Swal.fire({
								title: "Error!",
								text: Translations.swal.error[lang],
								icon: "error",
								confirmButtonText: "Ok"
							});
						}
					}
				}
			});
		}
	}

	return (
		<>
			<Flex
				justify={"space-between"}
				align={"center"}
				onMouseEnter={() => {
					setShowActions(true);
				}}
				onMouseLeave={() => {
					setShowActions(false);
				}}
				className={classes.wrapper}
			>
				<Flex key={message.id}>
					<Flex className={classes.imageWrapper}>
						<img
							src={`${config.api.API_URL}/${user?.uploads?.[0]?.path}` || ""}
							className={classes.image}
						/>
					</Flex>
					<Flex direction="column">
						<Flex align={"center"}>
							<span style={{ cursor: "pointer" }} onClick={() => setUserInfoDrawerOpen(true)}>
								{user.name[lang]}
							</span>
							<span className={classes.time}>{moment(message.createdAt).format("HH:mm")}</span>
						</Flex>
						<Text>{parse(message?.body ? message?.body : "")}</Text>
						{message.uploads && message.uploads.length > 0 && (
							<Flex direction="row" gap={20} mt="sm">
								{message.uploads.map((file, index) => (
									<Flex key={index} align="center" mt="xs">
										{file.type.startsWith("image/") ? (
											<div key={index} style={{ position: "relative" }}>
												<Image
													src={`${config.api.API_URL}/${file.path}`}
													alt={`${file.alt || file.path || ""}`}
													w={"100%"}
													h={"140px"}
													radius="sm"
													style={{
														objectFit: "cover"
													}}
												/>
												{!fullScreen && (
													<>
														<Button
															onClick={() => removeFile({ id: file._id })}
															color="red"
															id={fileId}
															style={{
																position: "absolute",
																top: 5,
																right: 5,
																opacity: 0.8,
																width: "20px",
																height: "20px",
																padding: "0"
															}}
														>
															<CloseIcon
																size={14}
																color={colorScheme === "dark" ? "white" : "black"}
																m={0}
															/>
														</Button>

														<Button
															onClick={(e) => {
																open(true);
																setModalStartIndex(message.uploads?.indexOf(file));
															}}
															color="cyan"
															id={fileId}
															style={{
																position: "absolute",
																bottom: 5,
																right: 5,
																opacity: 0.8,
																width: "30px",
																height: "30px",
																padding: "0"
															}}
														>
															<IconShare2
																size={14}
																color={colorScheme === "dark" ? "white" : "black"}
																m={0}
															/>
														</Button>
													</>
												)}
											</div>
										) : (
											<Text className={classes.attachmentFile}>{file.name}</Text>
										)}
										<Modal opened={opened} onClose={close} centered fullScreen>
											<ImageGallery
												startIndex={modalStartIndex}
												slideDuration={200}
												showThumbnails={true}
												items={message.uploads?.map((file, key) => {
													file.thumbnailHeight = "100px";
													file.thumbnail = `${config.api.API_URL}/${file.path}`;
													file.renderItem = () => {
														return (
															<Image
																src={`${config.api.API_URL}/${file.path}`}
																alt={`${file.name}`}
																w={"auto"}
																m={"0 auto"}
																h={"calc(100vh - 200px)"}
																objectFit={"contain"}
																key={key}
															/>
														);
													};
													file.renderThumbInner = () => {
														return (
															<Image
																src={`${config.api.API_URL}/${file.path}`}
																alt={`${file.name}`}
																style={{
																	height: "100px"
																}}
															/>
														);
													};
													return file;
												})}
												onScreenChange={(fullScreen) => {
													if (fullScreen) {
														document.body.style.overflow = "hidden";
														setFullScreen(true);
													} else {
														document.body.style.overflow = "auto";
														setFullScreen(false);
													}
												}}
											/>
										</Modal>
									</Flex>
								))}
							</Flex>
						)}
						{/* մի հատ հարցրու եթե կա message.forward-ը նոր նկարի, դաժե խնդիր ա տալիս */}
						{/* {Object.keys(message.forward).length > 0 && (
							<Blockquote
								color="grey"
								cite={`Forwarded from - ${message.forward?.from?.name?.[lang]}`}
								mt="sm"
							>
								{parse(message?.forward?.body ? message?.forward?.body : "")}
							</Blockquote>
						)} */}
					</Flex>
				</Flex>
				{!hideActions && showActions && (
					<ActionsMenu
						onReply={onReply}
						onForward={onForward}
						onDelete={() => {
							setDeleteConfirmationOpen(true);
						}}
						onStarred={onStarred}
						onPinned={onPinned}
						pinned={message.pinned}
						starred={message.starred}
					/>
				)}
			</Flex>
			<UserInfo
				open={userInfoDrawerOpen}
				onClose={() => setUserInfoDrawerOpen(false)}
				data={user}
			/>
			<Modal
				opened={deleteConfirmationOpen}
				onClose={() => setDeleteConfirmationOpen(false)}
				title="Delete message"
				centered
			>
				<Text>Are you sure to delete message ?</Text>
				<Checkbox
					mt="md"
					checked={deleteForEveryone}
					onChange={(e) => {
						setDeleteForAll(e.currentTarget.checked);
					}}
					label={T("deleteForEveryone", lang)}
				/>
				<Group mt="lg">
					<Button
						variant="filled"
						onClick={async () => {
							await onDelete(deleteForEveryone);
							setDeleteConfirmationOpen(false);
						}}
					>
						Delete
					</Button>
					<Button variant="default" onClick={() => setDeleteConfirmationOpen(false)}>
						Cancel
					</Button>
				</Group>
			</Modal>
		</>
	);
};
export default Message;
