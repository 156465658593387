import React from "react";
import { useUser } from "../../../helpers/userContext";
import { Avatar, Divider, Drawer, Flex, Text } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import Message from "../message/Message";

function PinnedStared({ open, onClose, data, title, partner }) {
	const { user } = useUser();
	return (
		<Drawer
			opened={open}
			onClose={onClose}
			title={<Text p="md">{title}</Text>}
			position="right"
			padding={0}
			overlayProps={{ backgroundOpacity: 0.2, blur: 0 }}
			closeButtonProps={{
				style: {
					marginRight: 5
				}
			}}
		>
			<Divider mb="sm" />
			{!data ? (
				<Flex direction="column" grow={1} align={"center"}>
					<Avatar color="grey" radius="xl">
						<IconSearch size={20} />
					</Avatar>
					<Text mt="sm">No Result</Text>
				</Flex>
			) : (
				data.map((item) => (
					<Message message={item} user={item.from === user._id ? user : partner} hideActions />
				))
			)}
		</Drawer>
	);
}

export default PinnedStared;
