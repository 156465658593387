import React, { useState } from "react";
import { TableComponent } from "./tableComponent";
import { useLang } from "../../helpers/language";
import Select from "react-select";
import { T } from "../../helpers/translator";

export function ToDoComponent({
	crud,
	user,
	Data,
	setter,
	value,
	limit,
	key,
	title,
	columns,
	conditionalRowStyles,
	pagination,
	customStyles
}) {
	const [data, setData] = useState(Data || []);
	const { lang } = useLang();
	const options = value.map((item) => {
		return { label: item.name.hy, value: item._id };
	});

	function addToList() {
		if (limit) {
			if (data.length < limit) {
				let temporaryCart = [...data];
				let selected;
				value.forEach((item) => {
					if (item._id === data) {
						selected = { ...item };
					}
				});
				if (selected) temporaryCart.push(selected);
				setter(temporaryCart);
				setData(undefined);
			}
		} else {
			let temporaryCart = [...data];
			let selected;
			value.forEach((item) => {
				if (item._id === data) {
					selected = { ...item };
				}
			});
			if (selected) temporaryCart.push(selected);
			setter(temporaryCart);
			setData(undefined);
		}
	}

	function onSelectChange(val) {
		setData(val.value);
	}

	if (user.ananunaki || crud.read) {
		return (
			<div className="form-element" key={key}>
				<label htmlFor="">{title}</label>
				<Select
					name="form-field-name"
					options={options}
					onChange={onSelectChange}
					className={"form-element form-element-wide"}
				/>
				{(user.ananunaki || crud.update) && (
					<button
						className={`btn btn-primary
        }`}
						onClick={addToList}
						disabled={!(data?.length > 0) || (limit && data?.length >= limit)}
					>
						<i className="fas fa-download"></i>
						<span style={{ marginLeft: 10 }}>{T("addToList", lang)}</span>
					</button>
				)}

				<TableComponent
					data={data}
					columns={columns}
					setter={setter}
					conditionalRowStyles={conditionalRowStyles}
					pagination={pagination}
					customStyles={customStyles}
				/>
			</div>
		);
	}
	return null;
}
