import React from "react";
import { ActionIcon, Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
const ActionsMenuButton = ({ onClick, icon, text }) => {
	const [opened, { close, open }] = useDisclosure(false);
	return (
		<Popover position="top" withArrow shadow="md" opened={opened}>
			<Popover.Target>
				<ActionIcon
					size="md"
					variant="transparent"
					color="rgba(0, 0, 0, 1)"
					onClick={onClick}
					onMouseEnter={open}
					onMouseLeave={close}
				>
					{icon}
				</ActionIcon>
			</Popover.Target>
			<Popover.Dropdown style={{ pointerEvents: "none" }}>
				<Text size="sm" ta="center">
					{text}
				</Text>
			</Popover.Dropdown>
		</Popover>
	);
};
export default ActionsMenuButton;
