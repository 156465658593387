import React from "react";
import { ListPage } from "../../ListPage";
import { useLang } from "../../../helpers/language";
import parse from "html-react-parser";
import config from "../../../config";
import { T } from "../../../helpers/translator";

export default function ProjectTypeList() {
	const { lang } = useLang();
	const columns = [
		{
			accessorFn: (row) => `${row?.name.en} ${row?.name.ru} ${row?.name.hy}`,
			permissionModel: "name",
			header: T("name", lang),
			grow: true,
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.name[lang] ? row.name[lang] : "...");
				return result;
			}
		}
	];

	return (
		<ListPage
			route={config.api.projectType}
			query={{}}
			sorting={{ index: "asc" }}
			uploadType={true}
			limit={null}
			permissionModel={"projectType"}
			// populate={["pc"]}
			columns={columns}
		/>
	);
}
