import React, { useState, useEffect } from "react";
import parse from "html-react-parser"; // Assuming parse is from 'html-react-parser' library
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useUser } from "../../../helpers/userContext";
import { useLang } from "../../../helpers/language";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Branch() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.branch;
	const { lang } = useLang();
	const { user } = useUser();

	const [index, setIndex] = useState("");
	const [nameEn, setNameEn] = useState("");
	const [nameRu, setNameRu] = useState("");
	const [nameHy, setNameHy] = useState("");

	const [allUsers, setAllUsers] = useState([]);

	const [responsibleStep1, setResponsibleStep1] = useState([]);
	const [responsibleStep10_39, setResponsibleStep10_39] = useState([]);
	const [usersStep10_39, setUsersStep10_39] = useState([]);
	const [responsibleStep11_12, setResponsibleStep11_12] = useState([]);
	const [responsibleStep17_18_19_20, setResponsibleStep17_18_19_20] = useState([]);

	const [permissions, setPermissions] = useState([]);

	const modelSendToServer = {
		index,
		name: {
			en: nameEn,
			ru: nameRu,
			hy: nameHy
		},
		steps: [
			{
				index: 1,
				responsible: responsibleStep1
			},
			{
				index: 10,
				responsible: responsibleStep10_39,
				users: usersStep10_39
			},
			{
				index: 11,
				responsible: responsibleStep11_12
			},
			{
				index: 12,
				responsible: responsibleStep11_12
			},
			{
				index: 17,
				responsible: responsibleStep17_18_19_20
			},
			{
				index: 18,
				responsible: responsibleStep17_18_19_20
			},
			{
				index: 19,
				responsible: responsibleStep17_18_19_20
			},
			{
				index: 20,
				responsible: responsibleStep17_18_19_20
			},
			{
				index: 39,
				responsible: responsibleStep10_39,
				users: usersStep10_39
			}
		].filter((step) => step.responsible.length > 0 || (step.users && step.users.length > 0))
	};

	const columns = [
		{
			accessorFn: (row) => `${row.firstName?.en} ${row.firstName?.ru} ${row.firstName?.hy}`,
			header: T("firstName", lang),
			filterVariant: "multi-select",
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.firstName?.[lang] ? row.firstName?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		},
		{
			accessorFn: (row) => `${row.lastName?.en} ${row.lastName?.ru} ${row.lastName?.hy}`,
			header: T("lastName", lang),
			filterVariant: "multi-select",
			Cell: (ReceivedObject, row = ReceivedObject.row.original) => {
				let result = parse(row.lastName?.[lang] ? row.lastName?.[lang] : "...");
				return result.length > 20 ? result.slice(0, 20) + "..." : result;
			}
		}
	];

	useEffect(() => {
		(async () => {
			NProgress.start();
			// all users
			let rawUsers = await API.get({
				route: config.api.user,
				query: {
					temporary: {
						$ne: true
					},
					workingStatus: "working"
				}
			});
			setAllUsers(rawUsers);

			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					populate: ["steps.responsible", "steps.users"]
				});
				setIndex(currentItem[0]?.index);
				setNameEn(currentItem[0]?.name?.en);
				setNameRu(currentItem[0]?.name?.ru);
				setNameHy(currentItem[0]?.name?.hy);

				currentItem[0]?.steps?.forEach((step) => {
					if (step.index === 1) {
						setResponsibleStep1(step.responsible);
					}
					if (step.index === 10 || step.index === 39) {
						setResponsibleStep10_39(step.responsible);
						setUsersStep10_39(step.users);
					}
					if (step.index === 11 || step.index === 12) {
						setResponsibleStep11_12(step.responsible);
					}
					if (step.index === 17 || step.index === 18 || step.index === 19 || step.index === 20) {
						setResponsibleStep17_18_19_20(step.responsible);
					}
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel="branch"
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: nameEn,
						setter: setNameEn,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						lang: "en",
						permissionModel: "name"
					},
					{
						value: nameRu,
						setter: setNameRu,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInRussian", lang),
						lang: "ru",
						permissionModel: "name"
					},
					{
						value: nameHy,
						setter: setNameHy,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInArmenian", lang),
						lang: "hy",
						permissionModel: "name"
					}
				]}
				cartComponent={{
					title: T("responsibleAndUsers", lang),
					data: [
						{
							placeholder: T("responsible", lang) + " - " + config.steps[0].name[lang] + " step1",
							value: responsibleStep1,
							setter: setResponsibleStep1,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder:
								T("responsible", lang) +
								" - " +
								config.steps[9].name[lang] +
								" step10" +
								" / " +
								config.steps[39].name[lang] +
								" step39",
							value: responsibleStep10_39,
							setter: setResponsibleStep10_39,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder:
								T("users", lang) +
								" - " +
								config.steps[9].name[lang] +
								" step10" +
								" / " +
								config.steps[39].name[lang] +
								" step39",
							value: usersStep10_39,
							setter: setUsersStep10_39,
							data: allUsers,
							columns: columns,
							permissionModel: "users",
							width: "220px"
						},
						{
							placeholder:
								T("responsible", lang) +
								" - " +
								config.steps[10].name[lang] +
								" step11" +
								" / " +
								config.steps[11].name[lang] +
								" step12",
							value: responsibleStep11_12,
							setter: setResponsibleStep11_12,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						},
						{
							placeholder:
								T("responsible", lang) +
								" - " +
								config.steps[15].name[lang] +
								" step16" +
								" / " +
								config.steps[16].name[lang] +
								" step17" +
								" / " +
								config.steps[17].name[lang] +
								" step18" +
								" / " +
								config.steps[18].name[lang] +
								" step19" +
								" / " +
								config.steps[19].name[lang] +
								" step20",
							value: responsibleStep17_18_19_20,
							setter: setResponsibleStep17_18_19_20,
							data: allUsers,
							columns: columns,
							permissionModel: "responsible",
							width: "220px"
						}
					]
				}}
			/>
		);
}
