import React, { useState, useEffect } from "react";
import SinglePage from "../../SinglePage";
import { useParams } from "react-router-dom";
import * as API from "../../../helpers/api";
import config from "../../../config";
import Loading from "../../modules/loading";
import { useLang } from "../../../helpers/language";
import { useUser } from "../../../helpers/userContext";
import NProgress from "nprogress";
import { T } from "../../../helpers/translator";

export default function Switch() {
	const [isBusy, setBusy] = useState(true);
	let id = useParams().id;
	const route = config.api.switch;
	const { lang } = useLang();
	const { user } = useUser();

	const [index, setIndex] = useState("");
	const [name, setName] = useState("");

	const [ports, setPorts] = useState(0);
	const [speed, setSpeed] = useState("");

	const [rooms, setRooms] = useState("");
	const [room, setRoom] = useState("");

	const [image, setImage] = useState("");
	const [permissions, setPermissions] = useState([]);

	const [working, setWorking] = useState(true);
	const [placed, setPlaced] = useState(true);

	const modelSendToServer = {
		index,
		name,
		room,
		ports,
		speed,
		working,
		placed
	};

	useEffect(() => {
		(async () => {
			NProgress.start();
			let rawRooms = await API.get({ route: config.api.room });
			if (rawRooms) {
				setRooms(rawRooms);
			}
			if (id !== "add") {
				let currentItem = await API.get({
					route,
					query: {
						_id: id
					},
					uploadType: true,
					limit: 1
				});
				setIndex(currentItem[0]?.index);
				setName(currentItem[0]?.name);
				setRoom(currentItem[0]?.room);
				setPorts(currentItem[0]?.ports);
				setSpeed(currentItem[0]?.speed);
				setWorking(currentItem[0]?.working);
				setPlaced(currentItem[0]?.placed);

				setImage(undefined);
				currentItem[0]?.uploads?.map((item) => {
					if (item.destiny === "image") setImage(item);
					return item;
				});
			}
			setBusy(false);
		})();
		// eslint-disable-next-line
	}, [isBusy]);

	useEffect(() => {
		let tempPermissions = [];
		user?.role?.permissions?.forEach((permission) => {
			if (permission.name === "ticket") {
				permission.inputs.forEach((input) => {
					tempPermissions.push(input);
				});
			}
		});
		setPermissions([...tempPermissions]);
	}, []);

	if (isBusy) return <Loading />;
	else
		return (
			<SinglePage
				isBusy={isBusy}
				setBusy={setBusy}
				route={route}
				id={id}
				permissions={permissions}
				permissionModel="switch"
				modelSendToServer={modelSendToServer}
				inputs={[
					{
						value: index,
						setter: setIndex,
						type: "number",
						usageType: "number",
						label: T("index", lang),
						placeholder: T("index", lang),
						permissionModel: "index"
					},
					{
						value: name,
						setter: setName,
						type: "text",
						usageType: "textfield",
						label: T("name", lang),
						placeholder: T("inputInLatin", lang),
						permissionModel: "name"
					},
					{
						value: ports,
						setter: setPorts,
						type: "number",
						usageType: "number",
						label: T("ports", lang),
						placeholder: T("ports", lang),
						permissionModel: "port"
					},
					{
						value: speed,
						setter: setSpeed,
						optionListValue: config.routerSpeeds,
						type: "optionList",
						usageType: "optionList",
						label: T("speed", lang),
						placeholder: T("speed", lang),
						permissionModel: "speed"
					},
					{
						value: room,
						setter: setRoom,
						optionListValue: rooms,
						type: "optionList",
						usageType: "optionList",
						label: T("room", lang),
						permissionModel: "room"
					},
					{
						value: working,
						setter: setWorking,
						type: "switch",
						usageType: "switch",
						switchValue: [
							{
								value: true,
								label: T("working", lang)
							},
							{
								value: false,
								label: T("notWorking", lang)
							}
						],
						label: T("working", lang),
						permissionModel: "working"
					},
					{
						value: placed,
						setter: setPlaced,
						type: "switch",
						usageType: "switch",
						switchValue: [
							{
								value: true,
								label: T("placed", lang)
							},
							{
								value: false,
								label: T("notPlaced", lang)
							}
						],
						label: T("placed", lang),
						permissionModel: "placed"
					}
				]}
				filesComponent={[
					{
						destiny: "image",
						file: image,
						setFile: setImage,
						filesUploadLimit: 1,
						acceptedFiles: ["image/*"],
						buttonAddText: "Add",
						buttonOpenText: "Open",
						buttonRemoveText: "Remove",
						dropzoneText: "There is no file, click to upload a new one",
						adminMode: user.role !== "editor",
						permissionModel: "image"
					}
				]}
			/>
		);
}
